export const formatPrice = (price) => {
    return price.toLocaleString("es-MX");
}

export const getBrowser = () => {
    const userAgent = navigator.userAgent.split(" ").reverse().join(" ");

    const browserMap = {
        'Firefox': /Firefox/,
        'Chrome': /Chrome/,
        // 'Safari': /Safari/,
        'Internet Explorer or Edge': /MSIE|Trident/,
        'Edg': /Edg/,
        'Opera': /OPR/,
        'MSIE': /Microsoft Internet Explorer/
    };

    let browser = 'Other'

    for (const item in browserMap) {
        if (browserMap[item].test(userAgent)) {
            browser = item;
        }
    }

    return browser;
}
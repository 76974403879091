import { HYDRA_API } from "./config/hydraAPI";

export const getToken = async () => {
  const result = await HYDRA_API()
    .get("token/get")
    .catch((error) => {
      return error.response ? error.response : error;
    });

  if (result.status === 200) {
    return { success: true, data: result.data.data[0] };
  } else {
    return { success: false };
  }
};

import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import get from "../services/api/properties";
import { useUser } from "../context/user";
import { APPLICATION_ID } from "../utils/enums";
import { postVisit } from "../services/api/visitLog";
import useNotification from "../utils/notifications";

const usePropertyDetail = () => {
  const { user } = useUser();
  const { id } = useParams();
  const { showNotification } = useNotification();

  const { mutate: mutateVisit } = useMutation({
    mutationFn: postVisit,
    onError: (err) => {
      console.log(err);
      showNotification("error", err);
    },
  });

  const { data: property, isLoading } = useQuery("property", {
    queryFn: async () => {
      const response = await get({
        seoCode: id,
      });
      return response?.data[0];
    },
    enabled: id !== undefined,
    onSuccess: (data) => {
      if (!data) return;

      mutateVisit({
        propertyId: data.id,
        origin: APPLICATION_ID,
        createdBy: user?.email ?? "Customer app",
      });
    },
  });

  return {
    property,
    isLoading,
  };
};

export default usePropertyDetail;

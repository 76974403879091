import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";

const ProductSliderV1 = ({ images }) => {
  const settings = {
    dots: true,
    speed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
      <div className="gallery-container">
        <Slider {...settings}>
          {images?.map((item, index) => (
              <img key={index} className="gallery-images" src={item?.path} alt="" />
          ))}
        </Slider>
      </div>
  );
};

export default ProductSliderV1;

import React, { useEffect } from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import Login from "./section-components/login";
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";

const LoginV1 = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PageHeader headertitle={t("Account")} subheader={t("Login")} t={t} />
      <Login t={t} />
      <Footer t={t} />
    </div>
  );
};

export default LoginV1;

/*eslint-disable*/
import { useQuery } from "react-query";
import get from "../services/api/properties";

const useOutstanding = () => {
 
  const { data: outstanding, isLoading } = useQuery("outstanding", {
    queryFn: async () => {
      
      const response = await get({ isPriority: true, limit: 5, offset: 0 });
      return response;
    },
  });

  return {
    isLoading,
    outstanding
  };
};

export default useOutstanding;

import React, { useState, useEffect } from "react";
import { Form, Select, Row, Input, Slider, Space } from "antd";
import { formatPrice } from "../../utils/format";

import { useGet as useGetCountry } from "../../hooks/country/useGet";
import { useGet as useGetState } from "../../hooks/state/useGet.js";
import { useGet as useGetCity } from "../../hooks/city/useGet";
import usePropertyFilters from "../../hooks/usePropertyFilters.js";
import ListWithMap from "../shop-components/tabs/map/index.js";
import { useLoadScript } from "@react-google-maps/api";

const Banner = ({
	propertyTypes,
	statusType,
	businessType,
	homeFilterData,
	searchProperties,
	priceRange,
	setPriceRange,
	t,
}) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS,
		libraries: ["places", "drawing"],
	});

	const { Option } = Select;

	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	const [showMap, setShowMap] = useState(false);

	const { setSelectedCity, selectedCity } = usePropertyFilters(t);

	const { countries, isLoading: isCountryLoading } = useGetCountry();
	const {
		states,
		isLoading: isStateLoading,
		refetch: refetchStates,
	} = useGetState(selectedCountry);
	const {
		cities,
		isLoading: isCityLoading,
		refetch: refetchCities,
	} = useGetCity(selectedState);

	useEffect(() => {
		if (selectedCountry !== null) {
			homeFilterData.setFieldsValue({
				stateId: undefined,
				cityId: undefined,
			});
			refetchStates({ countryId: selectedCountry });
		}
	}, [homeFilterData, refetchStates, selectedCountry]);

	useEffect(() => {
		if (selectedState !== null) {
			refetchCities({ stateId: selectedState });
			homeFilterData.setFieldsValue({
				cityId: undefined,
			});
		}
	}, [homeFilterData, refetchCities, selectedState]);

	return (
		<div className="ltn__slider-area ltn__slider-4">
			<div className="">
				<div
					className="ltn__slide-item-2 bg-image bg-overlay-theme-black-60"
					data-bs-bg={"https://cdn1.visiontravel.net/ubikme/home/4.jpg"}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 align-self-center">
								<div className="ltn__car-dealer-form-tab">
									<div className="ltn__tab-menu  text-uppercase">
										<Space>
											<div className="nav">
												<a
													className="active show"
													data-bs-toggle="tab"
													href="#ltn__form_tab_1_1"
													onClick={() => setShowMap(false)}
												>
													<i className="fas fa-home" />
													{t("Estate")}
												</a>
												<a
													data-bs-toggle="tab"
													href="#ltn__form_tab_1_2"
													onClick={() => setShowMap(true)}
												>
													<i className="fa-solid fa-map-location-dot" />
													{t("ViewMap")}
												</a>
											</div>
										</Space>
									</div>
									<div className="tab-content">
										<div
											className="tab-pane fade active show"
											id="ltn__form_tab_1_1"
										>
											<div className="car-dealer-form-inner">
												<div className="ltn__car-dealer-form-box row">
													<Form
														onFinish={searchProperties}
														form={homeFilterData}
														layout="vertical"
													>
														<Row>
															{/* Country */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="countryId"
																	label={
																		<label className="filter-labels">
																			{t("Country")}
																		</label>
																	}
																>
																	<Select
																		allowClear
																		disabled={
																			isCountryLoading ||
																			!countries
																		}
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t("Country")}
																		onChange={(value) => {
																			setSelectedCountry(
																				value
																			);
																		}}
																	>
																		{countries?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={item.id}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* State */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="stateId"
																	label={
																		<label className="filter-labels">
																			{t("State")}
																		</label>
																	}
																>
																	<Select
																		disabled={
																			isStateLoading ||
																			!selectedCountry ||
																			selectedCountry !==
																				homeFilterData.getFieldValue(
																					"countryId"
																				)
																		}
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t("State")}
																		onChange={(value) => {
																			setSelectedState(
																				value
																			);
																		}}
																	>
																		{states?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={item.id}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* City */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="cityId"
																	label={
																		<label className="filter-labels">
																			{t("City")}
																		</label>
																	}
																>
																	<Select
																		disabled={
																			isCityLoading ||
																			!selectedState ||
																			!selectedCountry ||
																			selectedState !==
																				homeFilterData.getFieldValue(
																					"stateId"
																				)
																		}
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t("City")}
																	>
																		{cities?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={item.id}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* Property Type */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="propertyTypeId"
																	label={
																		<label className="filter-labels">
																			{t("PropertyType")}
																		</label>
																	}
																>
																	<Select
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t(
																			"PropertyType"
																		)}
																	>
																		{propertyTypes?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={index + 1}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* Status Type */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="stateTypeId"
																	label={
																		<label className="filter-labels">
																			{t("EstateType")}
																		</label>
																	}
																>
																	<Select
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t(
																			"EstateType"
																		)}
																	>
																		{statusType?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={index + 1}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* BusinessType */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
																<Form.Item
																	name="businessTypeId"
																	label={
																		<label className="filter-labels">
																			{t("BusinessType")}
																		</label>
																	}
																>
																	<Select
																		showSearch
																		optionFilterProp="children"
																		size="large"
																		placeholder={t(
																			"BusinessType"
																		)}
																	>
																		{businessType?.map(
																			(item, index) => (
																				<Option
																					key={index}
																					value={index + 1}
																					className="options-select"
																				>
																					{item.name}
																				</Option>
																			)
																		)}
																	</Select>
																</Form.Item>
															</div>
															{/* Code */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-2 col-md-12">
																<Form.Item
																	name="code"
																	label={
																		<label className="filter-labels">
																			{t("Code")}
																		</label>
																	}
																>
																	<Input
																		placeholder={t("Code")}
																		value={`$${formatPrice(
																			priceRange.max
																		)}`}
																	/>
																</Form.Item>
															</div>
															{/* Price Range */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-4 col-md-12">
																<Form.Item
																	name="priceRange"
																	label={
																		<label className="filter-labels">
																			{t("PriceRange")}
																		</label>
																	}
																>
																	<div>
																		<div className="row">
																			<div className="col-md-6">
																				<Input
																					value={`$${formatPrice(
																						priceRange.min
																					)}`}
																					onChange={(
																						e
																					) => {
																						const rawValue =
																							e.target.value.replace(
																								/[\\$,\\.]/g,
																								""
																							); // Remove $ and . characters
																						const value =
																							rawValue
																								? parseInt(
																										rawValue
																								  )
																								: 0;
																						setPriceRange(
																							(
																								prevRange
																							) => ({
																								...prevRange,
																								min: value,
																							})
																						);
																					}}
																				/>
																			</div>
																			<div className="col-md-6">
																				<Input
																					defaultValue={0}
																					value={`$${formatPrice(
																						priceRange.max
																					)}`}
																					onChange={(
																						e
																					) => {
																						const rawValue =
																							e.target.value.replace(
																								/[\\$,\\.]/g,
																								""
																							); // Remove $ and . characters
																						const value =
																							rawValue
																								? parseInt(
																										rawValue
																								  )
																								: 0;
																						setPriceRange(
																							(
																								prevRange
																							) => ({
																								...prevRange,
																								max: value,
																							})
																						);
																					}}
																				/>
																			</div>
																		</div>
																		<Slider
																			defaultValue={[0, 0]}
																			handleStyle={{
																				borderColor: "#000",
																			}}
																			trackStyle={{
																				backgroundColor:
																					"#000",
																			}}
																			range
																			value={[
																				priceRange.min,
																				priceRange.max,
																			]}
																			onChange={(val) =>
																				setPriceRange({
																					min: val[0],
																					max: val[1],
																				})
																			}
																			min={0}
																			max={10000000}
																		/>
																	</div>
																</Form.Item>
															</div>
															{/* BTNs */}
															<div className="wrap-btn-filter">
																<button
																	type="submit"
																	className="btn-filter"
																>
																	{t("Search")}
																</button>
															</div>
														</Row>
													</Form>
												</div>
											</div>
										</div>
										<div
											className="tab-pane fade"
											id="ltn__form_tab_1_2"
										>
											<div className="car-dealer-form-inner">
												<div className="ltn__car-dealer-form-box row">
													{isLoaded && showMap && (
														<ListWithMap
															setSelectedCity={setSelectedCity}
															selectedCity={selectedCity}
															businessType={businessType}
															propertyTypes={propertyTypes}
															component="home-banner"
															t={t}
														/>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;

import React from "react";
import useBusinessType from "../../hooks/useBussinesType";
import usePropertyType from "../../hooks/usePropertyType";
import useStatusType from "../../hooks/useStatusType";
import { Tabs } from "antd";
import usePropertyFilters from "../../hooks/usePropertyFilters";
import usePagination from "../../hooks/usePagination";
import useWishList from "../../hooks/wishlist/useWishList";
import { useConfiguration } from "../../context/configurations";
import ModalWishList from "./modal-wishList";
import ModalInterested from "./interested/modal";
import useInterested from "../../hooks/useInterested";
import List from "./tabs/list";
import ListWithMap from "./tabs/map";
import useShopProperties from "../../hooks/useShopProperties";
import { useCommonContext } from "../../context/common";

const ShopGrid = ({ t }) => {
  const { properties, isLoading: isPropertyLoading } = useShopProperties();
  const { propertyTypes } = usePropertyType();
  const { businessType } = useBusinessType();
  const { statusType } = useStatusType();
  const {
    handleChange,
    handleChangeSelect,
    setLocation,
    cities,
    handleChangePrice,
    priceRange,
    payload,
    setSelectedCity,
    selectedCity,
  } = usePropertyFilters(t);
  const { nextOrPrev, isLoading: isPaginationLoading } = usePagination(payload);
  const { addWishList } = useWishList();
  const {
    validateUser,
    isLoading,
    form,
    sendInformation,
    isAccesptRegister,
    setIsAccesptRegister,
    setSelectedProperty,
  } = useInterested();
  const { open, setOpen, openInterested, setOpenInterested } =
    useConfiguration();

  const { shopTabs } = useCommonContext();

  const { TabPane } = Tabs;

  return (
    <div className="container-tabs-list">
      <Tabs
        defaultActiveKey={shopTabs}
        centered
        tabBarStyle={{ color: "#8D949C" }}
        style={{ width: "100%" }}
      >
        <TabPane tab={<i class="fa-solid fa-list fa-xl"></i>} key="1">
          <List
            addWishList={addWishList}
            properties={properties}
            propertyTypes={propertyTypes}
            businessType={businessType}
            statusType={statusType}
            handleChangeCheckBox={handleChange}
            handleChangeLocation={handleChangeSelect}
            setLocation={setLocation}
            cities={cities}
            handleChangePrice={handleChangePrice}
            priceRange={priceRange}
            validateUser={validateUser}
            setSelectedProperty={setSelectedProperty}
            nextOrPrev={nextOrPrev}
            setSelectedCity={setSelectedCity}
            isLoading={isPropertyLoading || isPaginationLoading}
            t={t}
          />
        </TabPane>
        <TabPane
          tab={<i class="fa-solid fa-map-location-dot fa-xl"></i>}
          key="2"
        >
          {shopTabs && (
            <ListWithMap
              setSelectedCity={setSelectedCity}
              selectedCity={selectedCity}
              propertyTypes={propertyTypes}
              businessType={businessType}
              component="shop"
              t={t}
            />
          )}
        </TabPane>
      </Tabs>

      {open && <ModalWishList setOpen={setOpen} open={open} t={t} />}
      {openInterested && (
        <ModalInterested
          form={form}
          setOpen={setOpenInterested}
          open={openInterested}
          sendInformation={sendInformation}
          isAccesptRegister={isAccesptRegister}
          setIsAccesptRegister={setIsAccesptRegister}
          isLoading={isLoading}
          t={t}
        />
      )}
    </div>
  );
};

export default ShopGrid;

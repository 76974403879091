import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import ShopRightSidebar from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const ShopRightSidebarPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Shop Right Sidebar" t={t}/>
        <ShopRightSidebar />
        <CallToActionV1 />
        <Footer t={t}/>
    </div>
}

export default ShopRightSidebarPage


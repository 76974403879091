/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const ConfigurationContext = createContext();

export const ConfigurationProvider = ({ children }) => {

  const [open, setOpen] = useState(false);
  const [openInterested, setOpenInterested] = useState(false);
  const showModal = () => {
    setOpen(!open);
  };
  const showModalInterested = () => {
    setOpenInterested(!openInterested);
  };

  return (
    <ConfigurationContext.Provider
      value={{
        open,
        setOpen,
        showModal,
        openInterested, 
        setOpenInterested,
        showModalInterested
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => {
  return useContext(ConfigurationContext);
};

import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/businessType";

const useBusinessType = () => {
  const [businessType, setBusinessType] = useState([]);

  const { isLoading } = useQuery("businessType", {
    queryFn: get,
    onSuccess: (data) => {
        setBusinessType(data);
    },
  });

  return {
    businessType,
    isLoading,
  };
};

export default useBusinessType;
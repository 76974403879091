import { useQuery } from "react-query";
import { RESOURCE_TYPE } from "../utils/enums";
import { get } from "../services/api/resource";

const useResource = (id = undefined) => {

  const { data: images, isLoading } = useQuery(["property-image", id], {
    queryFn: async () => {
        return await get({ propertyId: id, resourceTypeId: RESOURCE_TYPE.IMAGES });
    },
    enabled: id !== undefined
  });

  const { data: plans } = useQuery(["property-plans", id], {
    queryFn: async () => {
        return await get({ propertyId: id, resourceTypeId: RESOURCE_TYPE.PLANS });
    },
    enabled: id !== undefined
  });

  const { data: videos } = useQuery(["property-videos", id], {
    queryFn: async () => {
        return await get({ propertyId: id, resourceTypeId: RESOURCE_TYPE.VIDEO });
    },
    enabled: id !== undefined
  });

  return {
    images,
    isLoading,
    plans,
    videos
  };
};

export default useResource;

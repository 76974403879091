import { useQuery } from "react-query";
import { getThumbnails } from "../../services/api/properties";

export const useGetThumbnails = ({ propertyId }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["thumbnails", propertyId],
    queryFn: async () => {
      return getThumbnails({
        propertyId,
      });
    },
    keepPreviousData: true,
  });

  return {
    thumbnails: data,
    isLoading,
  };
};

import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import PortfolioDetails from './section-components/portfolio-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';


const Portfolio_Details = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Portfolio Details" t={t}/>
        <PortfolioDetails />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default Portfolio_Details


import HYDRA_API from "./config/hydraAPI";

export const registerUser = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}user/create`;

  const result = await HYDRA_API().post(url, body);
  return result.data;
};

export default registerUser;

export const getUser = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}user/get`;

  const result = await HYDRA_API().post(url, body);
  return result.data;
};
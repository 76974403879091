import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import registerUser, { getUser } from "../services/api/user";
import useNotification from "../utils/notifications";
import { validEmail } from "../utils/regex";
import { useState } from "react";

const useRegister = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const history = useHistory();
  const [existUser, setExistUser] = useState(false);
  const [messageExistUser, setMessageExistUser] = useState("");

  const register = async (values) => {
    const payload = {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      password: values.confirmPassword,
      userTypeId: 5,
      createdBy: "Service create B2C",
    };
    const response = await registerUser(payload);
    if (response.success) {
      showNotification("success", t("RegisterSuccess"));
      history.push("/login");
    } else {
      showNotification("error", response.message);
    }
    form.resetFields();
  };

  const { mutate: registerCustomer, isLoading } = useMutation({
    mutationFn: register,
    onSuccess: (data) => {
      queryClient.setQueryData(["register"], () => {
        return data;
      });
    },
  });

  const onChangeEmail = async (e) => {
    if (validEmail.test(e.target.value)) {
      const response = await getUser({ email: e.target.value });
      if (response?.data?.length > 0) {
        setExistUser(true);

        setMessageExistUser(
          t("MessageExistUser").replace(
            "{userType}",
            response?.data[0]?.userTypeName
          )
        );
      } else {
        setExistUser(false);
      }
    }
  };

  return {
    form,
    registerCustomer,
    isLoading,
    onChangeEmail,
    existUser,
    messageExistUser,
  };
};

export default useRegister;

import { Link } from "react-router-dom";
import { useGetThumbnails } from "../../../../hooks/property/useGetThumbnails";
import { Carousel } from "antd";

const CustomCarousel = ({ item }) => {
  const { thumbnails } = useGetThumbnails({
    propertyId: item.id,
  });

  return (
    <div className="custom-carousel">
      <Link
        rel="noopener noreferrer"
        target="_blank"
        to={`/product-details/${item?.seoCode}/${item?.id}`}
      >
        <Carousel arrows dots={false} infinite={false} draggable={true}>
          {thumbnails?.map((item, index) => (
            <div key={index}>
              <img src={item.url} alt="thumbnail" />
            </div>
          ))}
        </Carousel>
      </Link>
    </div>
  );
};

export default CustomCarousel;

import { Form } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const useFormBanner = () => {
  const history = useHistory();
  const [homeFilterData] = Form.useForm();
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });

  const searchProperties = () => {
    const data = homeFilterData.getFieldsValue();
    const payload = {
      propertyTypeId: data.propertyTypeId
        ? [data.propertyTypeId].join()
        : undefined,
      businessTypeId: data.businessTypeId
        ? [data.businessTypeId].join()
        : undefined,
      statusTypeId: data.stateTypeId ? [data.stateTypeId].join() : undefined,
      stateId: data.stateId ? data.stateId : undefined,
      cityId: data.cityId ? data.cityId : undefined,
      code: data.code ? data.code : undefined,
      priceRange,
    };
    history.push({
      pathname: "/shop",
      state: payload,
    });
  };

  return {
    homeFilterData,
    searchProperties,
    setPriceRange,
    priceRange,
  };
};

export default useFormBanner;

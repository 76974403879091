import React from "react";
import { Button, Tooltip, Row, Col } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

const FixedButtons = ({ validateUser, property, t }) => {

	return (property?.showSellerContact && property?.sellerPhone) ? (
		<Row
			gutter={[0, 10]}
			style={{
				position: "fixed",
				bottom: "150px",
				right: "-27px",
				zIndex: "2100",
				display: "block !important",
			}}
		>
			<Col span={24}>
				<Tooltip placement="left" title={t("Habla con nosotros")}>
					<Button
						type="primary"
						shape="circle"
            			onClick={() => window.open(`https://wa.me/${property?.sellerPhone}`, '_blank')}
						style={{
							width: "60px",
							height: "60px",
							background: "#FFC400",
							borderColor: "#FFC400",
						}}
						icon={<WhatsAppOutlined style={{ fontSize: "25px" }} />}
					></Button>
				</Tooltip>
			</Col>

			<Col span={24}>
				<Tooltip placement="left" title={t("Estoy interesado")}>
					<Button
						onClick={() => {
							validateUser(property.id);
						}}
						type="primary"
						shape="circle"
						style={{
							width: "60px",
							height: "60px",
							background: "#FFC400",
							borderColor: "#FFC400",
						}}
						icon={
							<i
								style={{ fontSize: "25px" }}
								class="fa-sharp fa-solid fa-house-laptop"
							></i>
						}
					></Button>
				</Tooltip>
			</Col>
		</Row>
	) : (
		<div
			style={{
				position: "fixed",
				bottom: "150px",
				right: "35px",
				zIndex: "2100",
				display: "block !important",
			}}
		>
			<Tooltip placement="left" title={t("Estoy interesado")}>
				<Button
					onClick={() => {
						validateUser(property.id);
					}}
					type="primary"
					shape="circle"
					style={{
						width: "60px",
						height: "60px",
						background: "#FFC400",
						borderColor: "#FFC400",
					}}
					icon={
						<i
							style={{ fontSize: "25px" }}
							class="fa-sharp fa-solid fa-house-laptop"
						></i>
					}
				></Button>
			</Tooltip>
		</div>
	);
};

export default FixedButtons;

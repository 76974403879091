import HYDRA_API from "./config/hydraAPI";

export const create = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}interested/create`;

  const result = await HYDRA_API().post(url, body);
  return result;
};

export const get = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}interested/get`;

  const result = await HYDRA_API().post(url, body);
  return result.data.data;
};

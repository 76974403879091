import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import AddListing from './section-components/add-listing';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const AddListing_V1 = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Add Listing" t={t} />
        <AddListing />
        <CallToActionV1 />
        <Footer t={t}/>
    </div>
}

export default AddListing_V1


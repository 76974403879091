/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
	const [shopTabs, setShopTabs] = useState("1")
    
    return (
		<CommonContext.Provider
			value={{
				shopTabs, 
				setShopTabs
			}}
		>
			{children}
		</CommonContext.Provider>
	);
};

export const useCommonContext = () => {
	return useContext(CommonContext);
};

export const interestedReq = (propertyId, payload) => {
    return {
        propertyId: propertyId,
        name: payload.name,
        lastName: payload.lastName,
        email: payload.email,
        indicative: payload.indicative,
        phone: payload.phone,
        aceptTerms: payload.aceptTerms,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        createdBy: payload.email
    }
}
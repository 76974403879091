import { useQuery } from "react-query";

import { get as getFeatureProperty } from "../../services/api/featureProperty";

export const useGet = (id = undefined) => {
  const { data: featureProperties, isLoading } = useQuery({
    queryKey: ["featureProperty", id],
    queryFn: async () => {
      const response = await getFeatureProperty(id);

      return response.filter((featureProperty) => featureProperty.active);
    },
    enabled: id !== undefined,
  });

  return {
    featureProperties,
    isLoading,
  };
};

import HYDRA_API from "./config/hydraAPI";

export const get = async () => {
  const url = `${process.env.REACT_APP_API_URL}feature/get`;

  const result = await HYDRA_API().post(url, { active: true });
  return result.data.data;
};

export default get;

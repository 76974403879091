import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/propertyType";

const usePropertyType = () => {
  const [propertyTypes, setPropertyTypes] = useState([]);

  const { isLoading } = useQuery("propertyTypes", {
    queryFn: get,
    onSuccess: (data) => {
      setPropertyTypes(data);
    },
    
  });

  return {
    propertyTypes,
    isLoading,
  };
};

export default usePropertyType;

import React from "react";
import { Modal } from "antd";
import { useGet as useCountries } from "../../../hooks/country/useGet";
import InterestedForm from "./form";

const ModalInterested = ({
  open,
  setOpen,
  form,
  sendInformation,
  isAccesptRegister,
  setIsAccesptRegister,
  isLoading,
  t,
}) => {
  const { countries } = useCountries();

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="modal-interested">
      <Modal
        open={open}
        title=""
        centered={true}
        maskClosable={false}
        onCancel={handleCancel}
        footer={false}
        width="50%"
        style={{ marginTop: "12%" }}
      >
        <InterestedForm
          countries={countries}
          form={form}
          sendInformation={sendInformation}
          isAccesptRegister={isAccesptRegister}
          setIsAccesptRegister={setIsAccesptRegister}
          isLoading={isLoading}
          t={t}
        />
      </Modal>
    </div>
  );
};
export default ModalInterested;

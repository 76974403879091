import { notification } from "antd";

const useNotification = () => {

  const showNotification = (type, message, position) => {
    notification[type]({
      message,
      placement: position ? position : "bottomLeft"
    });
  };

  return {
    showNotification,
  };
};

export default useNotification;

/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const useLocal = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : undefined;

  const [user, setUser] = useState(useLocal);

  const logOut = () => {
    localStorage.removeItem("userInfo");
    setUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        logOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

import HYDRA_API from "./config/hydraAPI";

export const get = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}property/get`;

  const result = await HYDRA_API().post(url, {
    ...payload,
    active: true,
    publish: true,
  });
  return result.data.data;
};

export const getRelated = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}property/related`;

  const result = await HYDRA_API().post(url, {
    ...payload,
    active: true,
    publish: true,
  });
  return result.data.data;
};

export const getApartmentPlans = async () => {
  const url = `${process.env.REACT_APP_API_URL}property/getPlans`;

  const result = await HYDRA_API().get(url);
  return result.data.data;
};

export const getForMap = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}property/getForMap`;

  const result = await HYDRA_API().post(url, payload);
  return result.data.data;
};

export const getThumbnails = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}property/getThumbnails`;

  const result = await HYDRA_API().post(url, payload);
  return result.data.data;
};

export default get;

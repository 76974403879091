
export const RESOURCE_TYPE = {
    IMAGES: 1,
    VIDEO: 2,
    PLANS: 3,
    DEEDS: 4,
    LEGAL_DOCUMENTS: 5,
    AGREEMENT: 6
}

export const HttpStatus = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    NOT_FOUND: 404
}

export const APPLICATION_ID = 2
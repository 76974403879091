import { formatPrice } from "../../../../utils/format";
import { Link } from "react-router-dom";

const ListItem = ({
	item,
	t
}) => {
	return (
		<Link to={`/product-details/${item?.seoCode}/${item?.id}`}>
			<div className="product-list-map-container-main">
				<img
					className="product-img-map"
					src={
						item.thumbnail ??
						"https://cdn1.visiontravel.net/ubikme/properties/images/default.gif"
					}
					alt=""
				/>
				<div className="product-list-map-container ">
					<p className="infowindow-title">{item.title.toUpperCase()}</p>
					<p>
						$ {formatPrice(Number(item.price))}{" "}
						<span className="infowindow-currency">
							{item.currencyCode}
						</span>{" "}
						{" | "}
						<span>{item.businessType}</span>
					</p>
					<p>
						{item.propertyType} | {item.statusType} | {item.builtArea}
						{item.measurementCode} | {item.roomsQuantity} {t("Bedrooms")} |{" "}
						{item.bathroomsQuantity} {t("Bath")}
					</p>
					<p>
						<i class="fa-solid fa-location-dot"></i> {item.city},{" "}
						{item.state}, {item.country}
					</p>
				</div>
			</div>
		</Link>
	);
};

export default ListItem;

import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user';
import ConditionsTerms from './section-components/terms.js'

const Terms = () => {
    const { t } = useTranslation();
    const { user, logOut} = useUser();
    return <div>
        <Navbar user={user} logOut={logOut}/>
        <PageHeader headertitle={t("Terms")} t={t} />
        <ConditionsTerms />
        <Footer t={t} />
    </div>
}

export default Terms

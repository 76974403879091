import HYDRA_API from "./config/hydraAPI";

export const get = async () => {
  const url = `${process.env.REACT_APP_API_URL}contactUsType/get`;

  const result = await HYDRA_API().get(url);
  return result.data.data;
};


export default get;

import HYDRA_API from "./config/hydraAPI";

export const create = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}wishList/create`;

  const result = await HYDRA_API().post(url, body);
  return result;
};

export const get = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}wishList/get`;

  const result = await HYDRA_API().post(url, body);
  return result.data.data;
};

export const remove = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}wishList/remove`;

  const result = await HYDRA_API().post(url, body);
  return result.data.data;
};

import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/feature";

const useFeature = () => {
  const [features, setFeatures] = useState([]);

  const { isLoading } = useQuery("features", {
    queryFn: get,
    onSuccess: (data) => {
      setFeatures(data);
    },
  });

  return {
    features,
    isLoading,
  };
};

export default useFeature;

import React from "react";
import AddressForm from "../my-address";
import useFormBanner from "../../hooks/useFormBanner";
import { useGet as useCountries } from "../../hooks/country/useGet";
import useAddress from "../../hooks/useAddress";
import { Col, Form, Input, Row } from "antd";

const MyAccount = ({ user, logOut, t }) => {
  const { cities, setLocation } = useFormBanner();

  const { countries } = useCountries();
  const { sendInformation, form, isLoading, address } = useAddress();

  return (
    <div className="liton__wishlist-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* PRODUCT TAB AREA START */}
            <div className="ltn__product-tab-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#ltn_tab_1_1"
                        >
                          {t("AccountDetails")} <i className="fas fa-user" />
                        </a>
                        {/* <a data-bs-toggle="tab" href="#ltn_tab_1_2">
                          {t("WishList")}
                          <i className="fa-solid fa-heart" />
                        </a> */}
                        {/* <a data-bs-toggle="tab" href="#ltn_tab_1_9">
                          Change Password <i className="fa-solid fa-lock" />
                        </a> */}
                        <a href="/" onClick={logOut}>
                          {t("LogOut")} <i className="fas fa-sign-out-alt" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="ltn_tab_1_1"
                      >
                        <div className="ltn__myaccount-tab-content-inner">
                          <p>{t("GeneralInfoAccount")}</p>
                          <div className="ltn__form-box">
                            <Form
                              form={form}
                              layout="vertical"
                              initialValues={{
                                name: user?.name,
                                lastName: user?.lastName,
                                email: user?.email,
                              }}
                            >
                              <Row gutter={16}>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  xl={12}
                                  className="item-form"
                                >
                                  <Form.Item
                                    name="name"
                                    label={
                                      <span className="form-labels">
                                        {t("Name")}
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: t("RequiredField"),
                                      },
                                      () => ({
                                        validator(_, value) {
                                          const specialCharsRegex =
                                            /[\^!@#$%&*()+={}[\]|\]\\<>,./~]/;
                                          if (specialCharsRegex.test(value)) {
                                            return Promise.reject(
                                              new Error(t("SpecialCharacters"))
                                            );
                                          }

                                          return Promise.resolve();
                                        },
                                      }),
                                      {
                                        max: 20,
                                        message: t("MaxLength").replace(
                                          "{N}",
                                          20
                                        ),
                                      },
                                      { type: "string" },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  xl={12}
                                  className="item-form"
                                >
                                  <Form.Item
                                    name="lastName"
                                    label={
                                      <span className="form-labels">
                                        {t("Lastname")}
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: t("RequiredField"),
                                      },
                                      () => ({
                                        validator(_, value) {
                                          const specialCharsRegex =
                                            /[\^!@#$%&*()+={}[\]|\]\\<>,./~]/;
                                          if (specialCharsRegex.test(value)) {
                                            return Promise.reject(
                                              new Error(t("SpecialCharacters"))
                                            );
                                          }

                                          return Promise.resolve();
                                        },
                                      }),
                                      {
                                        max: 20,
                                        message: t("MaxLength").replace(
                                          "{N}",
                                          20
                                        ),
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  xl={12}
                                  className="item-form"
                                >
                                  <Form.Item
                                    name="email"
                                    label={
                                      <span className="form-labels">
                                        {t("Email")}
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: t("RequiredField"),
                                      },
                                      {
                                        type: "email",
                                        message: t("NotValidEmail"),
                                      },
                                      {
                                        max: 50,
                                        message: t("MaxLength").replace(
                                          "{N}",
                                          50
                                        ),
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <div className="ltn__myaccount-tab-content-inner">
                        <p>{t("Address")}</p>
                        <AddressForm
                          form={form}
                          sendInformation={sendInformation}
                          countries={countries}
                          setLocation={setLocation}
                          cities={cities}
                          isLoading={isLoading}
                          address={address}
                          t={t}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;

import React from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import ShopLeftSidebar from "./shop-components/shop-left-sidebar";
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/user";

const ShopLeftSidebarPage = () => {
  const { t } = useTranslation();
  const { user, logOut } = useUser();
  return (
    <div>
      <Navbar user={user} logOut={logOut}/>
      <PageHeader headertitle={t("Estate")} t={t} />
      <ShopLeftSidebar t={t} />
      <Footer t={t} />
    </div>
  );
};

export default ShopLeftSidebarPage;

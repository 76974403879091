import React, { Component } from "react";

class Social extends Component {
  render() {
    return (
      <div  className="ltn__social-media">
        <ul>
          <li>
            <a href="https://www.instagram.com/ubikme_net/?igshid=YmMyMTA2M2Y%3D" target="_blank" title="Instagram" rel="noopener noreferrer">
              <i  className="fab fa-instagram" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;

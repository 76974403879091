import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/statusType";

const useStatusType = () => {
  const [statusType, setStatusTypes] = useState([]);

  const { isLoading } = useQuery("stateTypes", {
    queryFn: get,
    onSuccess: (data) => {
      setStatusTypes(data);
    }
  });

  return {
    statusType,
    isLoading,
  };
};

export default useStatusType;

import { useMutation } from "react-query";
import { changePassword as changePasswordFn } from "../../services/api/password";

import { useTranslation } from "react-i18next";
import useNotification from "../../utils/notifications";

export const useChangePassword = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const {
    mutate: changePasswordMutation,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: changePasswordFn,
    onError: (err) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        // Client error
        showNotification("warning", t("PasswordChangeBadRequest"));
      } else {
        showNotification("error", t("PasswordChangeServerError"));
      }
    },
  });

  const validatePayload = (values) => {
    const payload = {
      userId: values.userId || undefined,
      verificationCode: values.verificationCode || undefined,
      password: values.password || undefined,
    };

    return payload;
  };

  const changePassword = (values) => {
    const payload = validatePayload(values);

    changePasswordMutation(payload);
  };

  return {
    changePassword,
    isLoading,
    isSuccess,
  };
};

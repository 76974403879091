import { useQuery } from "react-query";
import { get } from "../services/api/contactUsType";

const useContactUsType = () => {

  const { data: contactUsType, isLoading } = useQuery("contactUsType", {
    queryFn: async () => {
      return await get();
    },
  });

  return {
    contactUsType,
    isLoading,
  };
};

export default useContactUsType;

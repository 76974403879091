import { useConfiguration } from "../context/configurations";
import { useUser } from "../context/user";
import { create } from "../services/api/interested";
import { Form } from "antd";
import { useState } from "react";
import { interestedReq } from "../utils/request";
import { useTranslation } from "react-i18next";
import useNotification from "../utils/notifications";
import { HttpStatus } from "../utils/enums";

const useInterested = (property) => {
	const { t } = useTranslation();
	const { user } = useUser();
	const { setOpenInterested } = useConfiguration();
	const [form] = Form.useForm();
	const [isAccesptRegister, setIsAccesptRegister] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const { showNotification } = useNotification(false);

	const validateUser = async (propertyId) => {
		if (user) {
			
			const payload =  {
				propertyId: selectedProperty || propertyId ,
				name: user.name,
				lastName: user.lastName,
				email: user.email,
				indicative: "",
				phone: "",
				aceptTerms: false,
				createdBy: user.email,
			};
            const response = await create(payload);
            if (response.status === HttpStatus.CREATED) {
                showNotification("success", t("MessageSuccessInterested"));
                setOpenInterested(false);
            } else showNotification("error", t("MessageErrorSend"));
            form.resetFields();
		} else {
			setOpenInterested(true);
		}
	};

	const sendInformation = async () => {
		setIsLoading(true);
		const payload = interestedReq(
			selectedProperty || property.id,
			form.getFieldsValue()
		);
		const response = await create({
			...payload,
			aceptTerms: isAccesptRegister,
		});
		if (response.status === HttpStatus.CREATED) {
			showNotification("success", t("MessageSuccessSend"));
			setOpenInterested(false);
		} else showNotification("error", t("MessageErrorSend"));
		form.resetFields();
		setIsLoading(true);
	};

	return {
		sendInformation,
		form,
		isAccesptRegister,
		setIsAccesptRegister,
		validateUser,
		setSelectedProperty,
		isLoading,
	};
};

export default useInterested;

/*eslint-disable*/
import React, { useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { formatPrice } from "../../utils/format";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Map = ({
	propertiesModified,
	setSelected,
	selected,
	isLoaded,
	filterPropertiesInBound,
	mapRef,
	isLoadingSearch,
	zoomChange,
	center,
	component,
	t,
}) => {
	const [activeMarker, setActiveMarker] = useState(null);

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};

	function handleLoad(map) {
		mapRef.current = map;
	}

	function handleCenter() {
		if (!mapRef.current) return;
		filterPropertiesInBound();

		const newPos = mapRef.current.getCenter().toJSON();
		setSelected({ lat: newPos.lat, lng: newPos.lng });
	}

	useEffect(() => {
		filterPropertiesInBound();
	}, [selected]);

	return (
		<React.Fragment>
			{isLoaded && (
				<GoogleMap
					id="data-example"
					onLoad={handleLoad}
					onDragEnd={handleCenter}
					mapContainerStyle={{
						width: "100%",
						height: component === "home-banner" ? "350px" : "600px",
						marginTop: "10px",
					}}
					zoom={zoomChange ? 15 : 9}
					center={selected ?? center}
					onZoomChanged={handleCenter}
				>
					{!isLoadingSearch &&
						propertiesModified?.map((item) => (
							<Marker
								key={item.id}
								onMouseHover={() => handleActiveMarker(item.id)}
								onClick={() => {
									handleActiveMarker(item.id);
								}}
								position={{
									lat: Number(item.latitude),
									lng: Number(item.longitude),
								}}
							>
								{activeMarker === item.id ? (
									<InfoWindow
										onCloseClick={() => setActiveMarker(null)}
										options={{ maxWidth: 260 }}
									>
										<div>
											<Link
												to={`/product-details/${item.seoCode}/${item.id}`}
											>
												<p className="infowindow-title">
													{item.title.toUpperCase()}
												</p>
												<img
													height={150}
													width={230}
													src={
														item.thumbnail ??
														"https://cdn1.visiontravel.net/ubikme/properties/images/default.gif"
													}
												/>

												<p>
													$ {formatPrice(Number(item.price))}{" "}
													<span className="infowindow-currency">
														{item.currencyCode}
													</span>
												</p>
												<p>
													{item.propertyType} | {item.statusType} |{" "}
													{item.builtArea}
													{item.measurementCode} |{" "}
													{item.roomsQuantity} {t("Bedrooms")} |{" "}
													{item.bathroomsQuantity} {t("Bath")}
												</p>
												<p>
													<i class="fa-solid fa-location-dot"></i>{" "}
													{item.city}, {item.state}, {item.country}
												</p>
											</Link>
										</div>
									</InfoWindow>
								) : null}
							</Marker>
						))}
				</GoogleMap>
			)}
		</React.Fragment>
	);
};

export default Map;

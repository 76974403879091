/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "@trendyol-js/react-carousel";
import { formatPrice } from "../../utils/format";
const Outstanding = ({ properties = [], t }) => {
  return (
    <div className="slider-featured  mt-5 mb-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title-area ltn__section-title-2--- text-center">
            <h1 className="section-title">{t("Featured")}</h1>
          </div>
        </div>
      </div>
      <Carousel
        responsive={true}
        swiping={true}
        slide={2}
        show={
          screen.width <= 600
            ? 1
            : screen.width > 600 && screen.width <= 1400
            ? 3
            : 4
        }
      >
        {properties?.data?.map((item, index) => (
          <div className="col-lg-12" key={index}>
            <div className="ltn__product-item ltn__product-item-4 text-center---">
              <div className="product-img go-top">
                <Link to={`/product-details/${item.seoCode}`}>
                  <img
                    src={
                      item?.thumbnail ||
                      "https://cdn1.visiontravel.net/ubikme/properties/images/default.gif"
                    }
                    alt="#"
                  />
                </Link>
                <div className="product-badge">
                  <ul>
                    <li className="sale-badge bg-green">{item.businessType}</li>
                  </ul>
                </div>
                <div className="product-img-location-gallery">
                  <div className="product-img-location">
                    <ul>
                      <li>
                        <Link to={`/product-details/${item.seoCode}`}>
                          <i className="flaticon-pin" /> {item.city},
                          {item.state}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="product-info">
                <div className="product-price">
                  <span>
                    {formatPrice(item?.price || 0)}
                    <label>{item.currencyCode}</label>
                  </span>
                </div>
                <h2 className="product-title go-top">
                  <Link to={`/product-details/${item.seoCode}`}>
                    {item.title}
                  </Link>
                </h2>
                <ul className="ltn__list-item-2 ltn__list-item-2-before">
                  <li>
                    <span>
                      {item.roomsQuantity} <i className="flaticon-bed" />
                    </span>
                    {t("Bedrooms")}
                  </li>
                  <li>
                    <span>
                      {item.bathroomsQuantity} <i className="flaticon-clean" />
                    </span>
                    {t("Bath")}
                  </li>
                  <li>
                    <span>
                      {item.privateArea}{" "}
                      <i className="flaticon-square-shape-design-interface-tool-symbol" />
                    </span>
                    {item.measurementCode}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Outstanding;

import { useQueryClient, useMutation } from "react-query";
import { forgotPassword as forgotPasswordFN } from "../../services/api/password";
import useNotification from "../../utils/notifications";
import { useTranslation } from "react-i18next";

export const useForgotPassword = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const {
    mutate: forgotMutation,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: forgotPasswordFN,
    onSuccess: async (resp) => {
      const data = resp.data.data[0];
      queryClient.setQueryData(["forgotPasswordData"], data);
    },
    onError: (err) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        // Client error
        showNotification("warning", t("ForgotPasswordBadRequest"));
      } else {
        showNotification("error", t("ForgotPasswordServerError"));
      }
    },
  });

  const validatePayload = (values) => {
    const payload = {
      email: values.email.trim() || undefined,
    };

    return payload;
  };

  const forgotPassword = (values) => {
    const payload = validatePayload(values);

    forgotMutation(payload);
  };

  return {
    forgotPassword,
    isLoading,
    isSuccess,
  };
};

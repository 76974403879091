import HYDRA_API from "./config/hydraAPI";

export const create = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}subscriber/create`;

  const result = await HYDRA_API().post(url, payload);
  return result.data;
};

export default create;

import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import Cart from './shop-components/cart';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const Cart_V1 = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Cart" t={t} />
        <Cart />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default Cart_V1


import { useQuery } from "react-query";
import { getApartmentPlans } from "../services/api/properties";

const usePlans = () => {

  const { data: plans, isLoading } = useQuery("plans", {
    queryFn: getApartmentPlans
  });

  return {
    plans,
    isLoading,
  };
};

export default usePlans;

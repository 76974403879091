import React, { useEffect, useState } from "react";

/* Components */
import { Form, Input } from "antd";
import { Link } from "react-router-dom";

import { useQueryClient } from "react-query";
import { useForgotPassword } from "../../hooks/forgot-password/useForgotPassword";
import { useVerifyCode } from "../../hooks/forgot-password/useVerifyCode";
import { useChangePassword } from "../../hooks/forgot-password/useChangePassword";

const ForgotPassword = ({ t }) => {
  const [step, setStep] = useState(1);

  const queryClient = useQueryClient();
  const forgotPasswordData = queryClient.getQueryData("forgotPasswordData");

  const {
    forgotPassword,
    isLoading: forgotIsLoading,
    isSuccess: forgotIsSuccess,
  } = useForgotPassword();

  const {
    verifyCode,
    isLoading: verifyIsLoading,
    isSuccess: verifyIsSuccess,
  } = useVerifyCode();

  const {
    changePassword,
    isLoading: changeIsLoading,
    isSuccess: changeIsSuccess,
  } = useChangePassword();

  /* SEND EMAIL */
  const handleEmailSubmit = ({ email }) => {
    forgotPassword({ email });
  };

  useEffect(() => {
    if (forgotIsSuccess && !forgotIsLoading) {
      setStep(2);
    }
  }, [forgotIsLoading, forgotIsSuccess]);

  /* VERIFY */
  const handleCodeVerification = ({ code }) => {
    verifyCode({
      code: code,
      userId: forgotPasswordData?.userId,
    });
  };

  useEffect(() => {
    if (verifyIsSuccess && !verifyIsLoading) {
      setStep(3);
    }
  }, [verifyIsLoading, verifyIsSuccess]);

  /* CHANGE PASSWORD */
  const handleResetSubmit = ({ password }) => {
    changePassword({
      password: password,
      verificationCode: forgotPasswordData?.verificationCode,
      userId: forgotPasswordData?.userId,
    });
  };

  useEffect(() => {
    if (changeIsSuccess && !changeIsLoading) {
      setStep(4);
    }
  }, [changeIsLoading, changeIsSuccess]);

  return (
    <div className="forgot-password-wrapper container">
      <div className="back-to-login">
        <Link to="/login">&#x21E6; {t("BackToLogin")}</Link>
      </div>

      {step === 1 ? (
        <>
          <h2>{t("ForgotPassword")}</h2>
          <p>
            {t("ForgotPasswordEmailInstructions")}{" "}
            <b>{forgotPasswordData?.email}</b>
          </p>
          <Form onFinish={handleEmailSubmit}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("RequiredField"),
                },
                {
                  type: "email",
                  message: t("NotValidEmail"),
                },
              ]}
            >
              <Input type="email" placeholder={t("Email")} />
            </Form.Item>
            <Form.Item>
              <button
                disabled={forgotIsLoading}
                className="theme-btn-1 btn btn-block"
                type="submit"
              >
                {t("SendResetEmail")}
              </button>
            </Form.Item>
          </Form>
        </>
      ) : step === 2 ? (
        <>
          <h2>{t("PasswordReset")}</h2>
          <p>
            {t("ResetCodeInstructions")} <b>{forgotPasswordData?.email}</b>
          </p>
          <Form onFinish={handleCodeVerification}>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: t("RequiredField"),
                },
                {
                  pattern: /^\d+$/,
                  message: t("CodeMustBeValid"),
                },
                {
                  len: 6,
                  message: t("CodeMustBe6Digits"),
                },
              ]}
            >
              <Input type="text" placeholder={t("VerificationCode")} />
            </Form.Item>
            <Form.Item>
              <button
                disabled={false} // to change to verifyIsLoading
                className="theme-btn-1 btn btn-block"
                type="submit"
              >
                {t("VerifyCode")}
              </button>
            </Form.Item>
          </Form>
        </>
      ) : step === 3 ? (
        <>
          <h2>{t("SetNewPasswordTitle")}</h2>
          <p>
            {t("SetNewPasswordDescription")} <b>{forgotPasswordData?.email}</b>
          </p>
          <Form onFinish={handleResetSubmit}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("RequiredField"),
                },
                {
                  min: 8,
                  message: t("MinLength").replace("{N}", 8),
                },
                {
                  max: 50,
                  message: t("MaxLength").replace("{N}", 50),
                },
                {
                  pattern: /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).*$/,
                  message: t("PasswordMustHave"),
                },
              ]}
            >
              <Input type="password" placeholder={t("NewPassword")} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("RequiredField"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder={t("ConfirmNewPassword")} />
            </Form.Item>
            <Form.Item>
              <button className="theme-btn-1 btn btn-block" type="submit">
                {t("SetNewPasswordTitle")}
              </button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <h2>{t("PasswordChangedSuccess")}</h2>
      )}
    </div>
  );
};

export default ForgotPassword;

import React from "react";

const Team = ({ t }) => {

  return (
    <div className="ltn__team-area pb-90 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="ltn__team-item ltn__team-item-3---">
              <div className="team-img">
                <img src={"https://cdn1.visiontravel.net/ubikme/about/3.jpg"} alt="" />
              </div>
              <div className="team-info">
                <h4>{t("IwantToBuy")}</h4>
                <p>{t("IwantToBuyDescription")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="ltn__team-item ltn__team-item-3---">
              <div className="team-img">
                <img src={"https://cdn1.visiontravel.net/ubikme/about/4.jpg"} alt="" />
              </div>
              <div className="team-info">
                <h4>{t("IwantToSell")}</h4>
                <p>{t("IwantToSellDescription")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="ltn__team-item ltn__team-item-3---">
              <div className="team-img">
                <img src={"https://cdn1.visiontravel.net/ubikme/about/5.jpg"} alt="" />
              </div>
              <div className="team-info">
                <h4>{t("IwantToRent")}</h4>
                <p>{t("IwantToRentDescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;

import { useQuery } from "react-query";
import { useToken } from "../../context/tokenVT";
import { get as getCity } from "../../services/api/cities";

export const useGet = (stateId) => {
  const { tokenVT } = useToken();

  const {
    data: cities,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["city"],
    queryFn: async () => {
      if (!stateId) return [];

      const response = await getCity({ stateId: stateId, tokenVT: tokenVT });

      return response.data.data;
    },
  });

  return {
    cities,
    isLoading,
    refetch,
  };
};

import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import PortfolioV1 from './section-components/portfolio-v1';
import CallToActonV2 from './section-components/call-to-action-v2';
import Sponsor from './section-components/sponsor-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const Portfolio_V1 = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Our Portfolio" subheader="Portfolio" t={t}/>
        <PortfolioV1 />
        <CallToActonV2 />
        <Sponsor />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default Portfolio_V1


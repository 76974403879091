import { Form } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../context/user";
import useSubscribers from "../../hooks/useSubscribers";
import Social from "../section-components/social";
import Copyright from "./copyright";

const Footer_v1 = ({ t }) => {
  const { user } = useUser();

  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  const { onChange, toSubscribe, email } = useSubscribers();

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="footer-widget footer-newsletter-widget">
                <div className="footer-newsletter-text">
                  <h4 className="footer-title">{t("Newsletter")}</h4>
                  <p>{t("NewsletterDescription")}</p>
                </div>
                <div className="footer-newsletter">
                  <Form onFinish={toSubscribe}>
                    <input
                      type="email"
                      name="email"
                      onChange={onChange}
                      value={email.email}
                      placeholder={t("Email")}
                    />
                    <div className="btn-wrapper">
                      <button className="theme-btn-1 btn" type="submit">
                        <i className="fas fa-location-arrow" />
                      </button>
                    </div>
                  </Form>
                </div>
                <div className="ltn__social-media footer-newsletter-social">
                  <Social />
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img
                      className="logo-img"
                      src={
                        "https://cdn1.visiontravel.net/ubikme/logo/logo-yellow.png"
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12"></div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t("Company")}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/about">{t("About")}</Link>
                    </li>
                    <li>
                      <Link to="/shop">{t("Estate")}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("Contact")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t("Services")}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to={user ? "/my-account" : "/login"}>
                        {t("Login")}
                      </Link>
                    </li>
                    {/* <li>
                        <Link to="/checkout">{t("Checkout")}</Link>
                      </li> */}
                    <li>
                      <Link to="/terms">{t("Terms")}</Link>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t("CustomerCare")}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to={user ? "/my-account" : "/login"}>
                        {t("MyAccount")}
                      </Link>
                    </li>
                    <li>
                      <Link to={user ? "/wishlist" : "/login"}>
                        {t("WishList")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright t={t} />
    </footer>
  );
};

export default Footer_v1;

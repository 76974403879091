import React from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/format";
import useWishListRemoveItem from "../../hooks/wishlist/useWishListRemoveItem";

const WishList = ({ wishList }) => {
  const { removeItem, isLoading } = useWishListRemoveItem();

  const handleRemoveWishList = (id) => () => {
    removeItem({ propertyId: id });
  };

  return (
    <div className="liton__wishlist-area mb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="shoping-cart-inner">
              <div className="shoping-cart-table table-responsive">
                <table className="wishlist-table">
                  <tbody>
                    {wishList &&
                      wishList.map((item, index) => (
                        <tr key={index}>
                          <td className="cart-product-image">
                            <Link
                              to={`/product-details/${item?.seoCode}/${item?.id}`}
                            >
                              <img
                                src={
                                  item?.thumbnail ??
                                  "https://cdn1.visiontravel.net/ubikme/properties/images/default.gif"
                                }
                                alt="/"
                              />
                            </Link>
                          </td>
                          <td className="cart-product-info">
                            <h4 className="go-top">
                              <Link
                                to={`/product-details/${item?.seoCode}/${item?.id}`}
                              >
                                {item?.title}
                              </Link>
                            </h4>
                          </td>
                          <td className="cart-product-price">
                            {formatPrice(item?.price)}{" "}
                            <span>{item?.currencyCode}</span>
                          </td>
                          <td
                            className="cart-product-remove"
                            disabled={isLoading}
                            onClick={handleRemoveWishList(item?.id)}
                          >
                            <i className="fas fa-remove" />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;

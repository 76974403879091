import { useMutation, useQueryClient } from "react-query";
import { verifyCode as verifyCodeFN } from "../../services/api/password";
import useNotification from "../../utils/notifications";
import { useTranslation } from "react-i18next";

export const useVerifyCode = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  let verificationCode = "";

  const {
    mutate: verifyCodeMutation,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: verifyCodeFN,
    onSuccess: () => {
      const forgotPasswordData = queryClient.getQueryData("forgotPasswordData");
      queryClient.setQueryData("forgotPasswordData", {
        ...forgotPasswordData,
        verificationCode: verificationCode,
      });
    },
    onError: (err) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        // Client error
        showNotification("warning", t("VerifyCodeBadRequest"));
      } else {
        showNotification("error", t("VerifyCodeServerError"));
      }
    },
  });

  const validatePayload = (values) => {
    verificationCode = values.code;

    const payload = {
      userId: values.userId || undefined,
      verificationCode: values.code || undefined,
    };

    return payload;
  };

  const verifyCode = (values) => {
    const payload = validatePayload(values);

    verifyCodeMutation(payload);
  };

  return {
    verifyCode,
    isLoading,
    isSuccess,
  };
};

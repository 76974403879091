import { useMutation, useQueryClient } from "react-query";
import { get, getRelated } from "../services/api/properties";

const usePagination = (payloadProperty) => {
  const limit = 10;

  const queryClient = useQueryClient();

  const validateAction = async (action) => {
    const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
    let payload = {
      limit,
      offset: action.selected,
      userId: agencyInfo?.user?.id,
    };
    return (await agencyInfo)
      ? getRelated({ ...payloadProperty, ...payload })
      : get({ ...payloadProperty, ...payload });
  };

  const { mutate: nextOrPrev, isLoading } = useMutation({
    mutationFn: validateAction,
    onSuccess: (data) => {
      queryClient.setQueryData(["properties"], () => {
        return data;
      });
      window.scroll(0, 0);
    },
  });

  return {
    nextOrPrev,
    isLoading,
  };
};

export default usePagination;

import React, { useEffect } from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import ForgotPassword from "./section-components/forgot-password";
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle={t("ForgotPass")}
        subheader={t("ForgotPass")}
        t={t}
      />
      <ForgotPassword t={t} />
      <Footer t={t} />
    </div>
  );
};

export default ForgotPasswordPage;

/*eslint-disable*/
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import "@reach/combobox/styles.css";
import Map from "../../../section-components/map";
import { useState } from "react";
import { Col, Row, Select } from "antd";

export default function Places({
	propertiesforMap,
	propertiesModified,
	setPropertiesModified,
	selected,
	setSelected,
	setFieldValue,
	filterPropertiesInBound,
	mapRef,
	isLoadingSearch,
	values,
	errors,
	center,
	searchWithoutCity,
	component,
	propertyTypes,
	businessType,
	handleChangeBusinessType,
	handleChangePropertyTypes,
	t,
}) {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS,
		libraries: ["places", "drawing"],
	});

	if (!isLoaded) return <div>Cargando...</div>;
	return (
		<Autocomplete
			t={t}
			isLoaded={isLoaded}
			values={values}
			selected={selected}
			setSelected={setSelected}
			setFieldValue={setFieldValue}
			propertiesforMap={propertiesforMap}
			propertiesModified={propertiesModified}
			setPropertiesModified={setPropertiesModified}
			filterPropertiesInBound={filterPropertiesInBound}
			mapRef={mapRef}
			isLoadingSearch={isLoadingSearch}
			center={center}
			searchWithoutCity={searchWithoutCity}
			errors={errors}
			component={component}
			propertyTypes={propertyTypes}
			businessType={businessType}
			handleChangeBusinessType={handleChangeBusinessType}
			handleChangePropertyTypes={handleChangePropertyTypes}
		/>
	);
}

function Autocomplete({
	isLoaded,
	setSelected,
	setFieldValue,
	values,
	selected,
	propertiesforMap,
	propertiesModified,
	setPropertiesModified,
	filterPropertiesInBound,
	mapRef,
	isLoadingSearch,
	center,
	searchWithoutCity,
	component,
	propertyTypes,
	businessType,
	handleChangeBusinessType,
	handleChangePropertyTypes,
	t,
}) {
	const [zoomChange, setZoomChange] = useState(false);

	return (
		<>
			<div className="places-container">
				<PlacesAutocomplete
					setSelected={setSelected}
					setFieldValue={setFieldValue}
					values={values}
					setZoomChange={setZoomChange}
					searchWithoutCity={searchWithoutCity}
					propertyTypes={propertyTypes}
					businessType={businessType}
					handleChangeBusinessType={handleChangeBusinessType}
					handleChangePropertyTypes={handleChangePropertyTypes}
					t={t}
				/>
			</div>
			<div className="mt-20">
				<Map
					propertiesforMap={propertiesforMap}
					propertiesModified={propertiesModified}
					setPropertiesModified={setPropertiesModified}
					values={values}
					selected={selected}
					setSelected={setSelected}
					setFieldValue={setFieldValue}
					isLoaded={isLoaded}
					filterPropertiesInBound={filterPropertiesInBound}
					mapRef={mapRef}
					isLoadingSearch={isLoadingSearch}
					zoomChange={zoomChange}
					center={center}
					component={component}
					t={t}
				/>
			</div>
		</>
	);
}

const PlacesAutocomplete = ({
	setSelected,
	setZoomChange,
	searchWithoutCity,
	propertyTypes,
	businessType,
	handleChangeBusinessType,
	handleChangePropertyTypes,
	t,
}) => {
	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete();

	const handleSelect = async (address) => {
		setValue(address, false);
		clearSuggestions();

		const results = await getGeocode({ address });
		const { lat, lng } = await getLatLng(results[0]);
		setSelected({ lat, lng });
		setZoomChange(true);
		searchWithoutCity();
	};

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} sm={12}>
				<Select
					allowClear
					className="map-home-select"
					showSearch
					optionFilterProp="children"
					size="large"
					placeholder={t("SearchByLocation")}
					notFoundContent={t("SearchByLocation")}
					onKeyUp={(e) => setValue(e.target.value)}
					onChange={(value) => handleSelect(value)}
				>
					{status === "OK" &&
						data.map(({ place_id, description }) => (
							<Option
								key={place_id}
								value={description}
								className="options-select"
							>
								{description}
							</Option>
						))}
				</Select>
			</Col>
			<Col xs={24} sm={6}>
				<Select
					showSearch
					optionFilterProp="children"
					className="map-home-select"
					size="large"
					placeholder={t("BusinessType")}
					onChange={handleChangeBusinessType}
				>
					{businessType?.map((item, index) => (
						<Option
							key={index}
							value={index + 1}
							className="options-select"
						>
							{item.name}
						</Option>
					))}
				</Select>
			</Col>
			<Col xs={24} sm={6}>
				<Select
					showSearch
					optionFilterProp="children"
					className="map-home-select"
					size="large"
					placeholder={t("PropertyType")}
					onChange={handleChangePropertyTypes}
				>
					{propertyTypes?.map((item, index) => (
						<Option
							key={index}
							value={index + 1}
							className="options-select"
						>
							{item.name}
						</Option>
					))}
				</Select>
			</Col>
		</Row>
	);
};

import React from "react";

const AboutV4 = ({ t }) => {
  return (
    <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 align-self-auto history-section">
            <h1 className="section-title">{t("AboutHistoryTitle")}</h1>
          </div>
          <div style={{ textAlign: "justify" }}>
            <p>{t("AboutHistoryP1")}</p>
            <p>{t("AboutHistoryP2")}</p>
            <p>{t("AboutHistoryP3")}</p>
            <p>{t("AboutHistoryP4")}</p>
            <p>{t("AboutHistoryP5")}</p>
          </div>

          <div className="col-lg-12 mt-40">
            <div style={{ display: "flex" }}>
              <img
                src={"https://cdn1.visiontravel.net/ubikme/about/1.jpg"}
                alt="About Us "
                width={400}
                style={{ objectFit: "cover" }}
              />

              <div style={{ marginLeft: "3rem", textAlign: "justify" }}>
                <p>{t("AboutDescriptionP1")}</p>
                <p>{t("AboutDescriptionP2")}</p>
                <p>{t("AboutDescriptionP3")}</p>

                <div className="mt-30">
                  <p className="offer-description">{t("AboutDescriptionP4")}</p>
                  <ul className="ltn__list-item-half">
                    <li>{t("AboutDescriptionLi1")}</li>
                    <li>{t("AboutDescriptionLi2")}</li>
                    <li>{t("AboutDescriptionLi3")}</li>
                    <li>{t("AboutDescriptionLi4")}</li>
                    <li>{t("AboutDescriptionLi5")}</li>
                    <li>{t("AboutDescriptionLi6")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-50">
            <hr className="m-0 p-0" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV4;

/* <div className="col-lg-6 align-self-center mt-5">
		<div className="about-us-info-wrap">
			<div className="section-title-area ltn__section-title-2--- mb-20">
				<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
					{t("AboutAgentTitle")}
				</h6>
				<h1 className="section-title">
					{t("AboutAgentSubTitle")}
				</h1>
				<p>{t("AboutAgentP1")}</p>
				<p>{t("AboutAgentP2")}</p>
			</div>
		</div>
	</div>

	<div className="col-lg-6 align-self-center  mt-5">
		<div className="about-us-img-wrap about-img-left">
			<img
				src={
					"https://cdn1.visiontravel.net/ubikme/about/2.jpg"
				}
				alt="About Us "
			/>
		</div>
	</div> */

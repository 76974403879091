/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
	const [payloadFilters, setPayloadFilters] = useState({});
    const [selectedCity, setSelectedCity] = useState(null);
    
    return (
		<PropertyContext.Provider
			value={{
				payloadFilters,
				setPayloadFilters,
                selectedCity, 
                setSelectedCity
			}}
		>
			{children}
		</PropertyContext.Provider>
	);
};

export const usePropertyContext = () => {
	return useContext(PropertyContext);
};

import { VT_API } from "./config/hydraAPI";

export const getByText = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}city/getByText/${payload.text}`;

  const result = await VT_API(payload.tokenVT).get(url);
  return result.data.data;
};

export const get = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}city/get`;
  return await VT_API(payload.tokenVT).post(url, payload);
};

export default getByText;

import React from "react";
import { Checkbox, Form, Input, Row, Col, Select } from "antd";
const InterestedForm = ({
	countries,
	form,
	sendInformation,
	isAccesptRegister,
	setIsAccesptRegister,
    isLoading,
	t,
}) => {
	const { Option } = Select;

	return (
		<div className="ltn__contact-message-area mb-120 mb--100 pb-100">
			<div className="container contact-form ">
				<h4 className="title-2">{t("PropertyInterested")}</h4>
				<Form form={form} layout="vertical" onFinish={sendInformation}>
					<Row gutter={16}>
						<Col xs={24} sm={12} md={12} xl={12}>
							<Form.Item
								name="name"
								label={<span className="form-labels">{t("Name")}</span>}
								rules={[
									{ required: true, message: t("RequiredField") },
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} xl={12}>
							<Form.Item
								name="lastName"
								label={
									<span className="form-labels">{t("Lastname")}</span>
								}
								rules={[
									{ required: true, message: t("RequiredField") },
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} xl={12} className="item-form">
							<Form.Item
								name="email"
								label={
									<span className="form-labels">{t("Email")}</span>
								}
								rules={[
									{ required: true, message: t("RequiredField") },
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} xl={12} className="item-form">
							<Form.Item
								name="indicative"
								label={
									<span className="form-labels">{t("Country")}</span>
								}
								rules={[
									{ required: true, message: t("RequiredField") },
								]}
							>
								<Select showSearch optionFilterProp="children">
									{countries?.map((country, key) => (
										<Option
											className="options-select"
											key={key}
											value={country.indicative}
										>
											{country.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={12} xl={12} className="item-form">
							<Form.Item
								name="phone"
								label={
									<span className="form-labels">{t("Phone")}</span>
								}
								rules={[
									{ required: true, message: t("RequiredField") },
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={24} xl={24} className="item-form">
							<Form.Item
								name="aceptTerms"
								valuePropName="checked"
							>
								<Checkbox
									onChange={() =>
										setIsAccesptRegister(!isAccesptRegister)
									}
								>
									<span className="form-labels">
										{t("AcceptRegister")}
									</span>
								</Checkbox>
							</Form.Item>
						</Col>
						{isAccesptRegister && (
							<>
								<Col
									xs={24}
									sm={12}
									md={12}
									xl={12}
									className="item-form"
								>
									<Form.Item
										name="password"
										label={
											<span className="form-labels">
												{t("Password")}
											</span>
										}
										rules={[
											{
												required: true,
												message: t("RequiredField"),
											},
											{ min: 8, message: t("LengthPass") },
										]}
									>
										<Input type="password" />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									sm={12}
									md={12}
									xl={12}
									className="item-form"
								>
									<Form.Item
										name="confirmPassword"
										dependencies={["password"]}
										label={
											<span className="form-labels">
												{t("ConfirmPassword")}
											</span>
										}
										rules={[
											{
												required: true,
												message: t("RequiredField"),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue("password") === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(t("PasswordsDontMatch"))
													);
												},
											}),
										]}
									>
										<Input type="password" />
									</Form.Item>
								</Col>
							</>
						)}
					</Row>
					<div className="btn-wrapper mt-0">
						<button
							className="btn theme-btn-1 btn-effect-1 text-uppercase"
							type="submit"
						>
							{t("Send")}
						</button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default InterestedForm;

import React from "react";
import { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select } from "antd";

import { useGet as useGetCountry } from "../hooks/country/useGet";
import { useGet as useGetState } from "../hooks/state/useGet.js";
import { useGet as useGetCity } from "../hooks/city/useGet";

const AddressForm = ({ form, sendInformation, isLoading, address, t }) => {
  const { Option } = Select;

  /* States */
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  /* Hooks */
  const { countries, isLoading: isCountryLoading } = useGetCountry();
  const {
    states,
    isLoading: isStateLoading,
    refetch: refetchStates,
  } = useGetState(selectedCountry);
  const {
    cities,
    isLoading: isCityLoading,
    refetch: refetchCities,
  } = useGetCity(selectedState);

  /* UseEffects */

  useEffect(() => {
    const checkFormValues = () => {
      const defaultCountryId = form.getFieldValue("countryId");
      const defaultStateId = form.getFieldValue("stateId");
      const defaultCityId = form.getFieldValue("cityId");

      if (
        defaultCountryId === undefined ||
        defaultStateId === undefined ||
        defaultCityId === undefined
      ) {
        return false;
      }

      if (defaultCountryId) {
        const country = countries?.find((c) => c.id === defaultCountryId);
        if (country) {
          setSelectedCountry(country.id);
        }
      }

      if (defaultStateId) {
        const state = states?.find((s) => s.id === defaultStateId);
        if (state) {
          setSelectedState(state.id);
        }
      }

      if (defaultCityId) {
        const city = cities?.find((c) => c.id === defaultCityId);
        if (city) {
          form.setFieldsValue({ cityId: city.id });
        }
      }

      setIsLoaded(true);
      return true;
    };

    if (!checkFormValues()) {
      const intervalId = setInterval(() => {
        if (checkFormValues()) {
          clearInterval(intervalId);
        }
      }, 100);
    }
  }, [form, countries, states, cities]);

  useEffect(() => {
    if (selectedCountry !== null) {
      refetchStates({ countryId: selectedCountry });
    }
  }, [selectedCountry, refetchStates]);

  useEffect(() => {
    if (selectedState !== null) {
      refetchCities({ stateId: selectedState });
    }
  }, [refetchCities, selectedState]);

  return (
    <Form form={form} layout="vertical" onFinish={sendInformation}>
      <Row gutter={16}>
        {/* Country */}
        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="countryId"
            label={<label className="filter-labels">{t("Country")}</label>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select
              allowClear
              disabled={isCountryLoading || !countries}
              showSearch
              optionFilterProp="children"
              size="large"
              placeholder={t("Country")}
              onChange={(value) => {
                setSelectedCountry(value);
                form.setFieldsValue({ stateId: undefined, cityId: undefined });
                setSelectedState(null);
              }}
            >
              {countries?.map((item, index) => (
                <Option key={index} value={item.id} className="options-select">
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* State */}
        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="stateId"
            label={<label className="filter-labels">{t("State")}</label>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select
              disabled={
                isStateLoading ||
                !selectedCountry ||
                selectedCountry !== form.getFieldValue("countryId")
              }
              showSearch
              allowClear
              optionFilterProp="children"
              size="large"
              placeholder={t("State")}
              onChange={(value) => {
                setSelectedState(value);
                form.setFieldsValue({ cityId: undefined });
              }}
            >
              {states?.map((item, index) => (
                <Option key={index} value={item.id} className="options-select">
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* City */}
        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="cityId"
            label={<label className="filter-labels">{t("City")}</label>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select
              disabled={
                isCityLoading ||
                !selectedState ||
                !selectedCountry ||
                selectedState !== form.getFieldValue("stateId")
              }
              showSearch
              allowClear
              optionFilterProp="children"
              size="large"
              placeholder={t("City")}
            >
              {cities?.map((item, index) => (
                <Option key={index} value={item.id} className="options-select">
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={12} xl={12}>
          <Form.Item
            name="addressLine1"
            label={<span className="form-labels">{t("Address")}</span>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Input placeholder={t("Address")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="addressLine2"
            label={<span className="form-labels">{t("AditionalAddress")}</span>}
          >
            <Input placeholder={t("AditionalAddress")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="postalCode"
            label={<span className="form-labels">{t("ZipCode")}</span>}
            rules={[
              { required: true, message: t("RequiredField") },
              { min: 4, message: t("MinLength").replace("{N}", 4) },
              { max: 10, message: t("MaxLength").replace("{N}", 10) },
            ]}
          >
            <Input placeholder={t("ZipCode")} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="indicative"
            label={<span className="form-labels">{t("Indicative")}</span>}
            rules={[{ required: true, message: t("RequiredField") }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={t("Indicative")}
            >
              {countries?.map((country, key) => (
                <Option
                  className="options-select"
                  key={key}
                  value={country.indicative}
                >
                  {country.name} +{country.indicative}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} xl={12} className="item-form">
          <Form.Item
            name="phone"
            label={<span className="form-labels">{t("Phone")}</span>}
            rules={[
              { required: true, message: t("RequiredField") },
              { min: 8, message: t("MinLength").replace("{N}", 8) },
              { max: 12, message: t("MaxLength").replace("{N}", 12) },
            ]}
          >
            <Input placeholder={t("Phone")} />
          </Form.Item>
        </Col>
      </Row>
      <div className="btn-wrapper mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="submit"
          disabled={
            isLoading ||
            isCountryLoading ||
            isStateLoading ||
            isCityLoading ||
            !isLoaded
          }
        >
          {address?.length > 0 ? t("Update") : t("Send")}
        </button>
      </div>
    </Form>
  );
};

export default AddressForm;

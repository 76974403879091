import React from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

const ModalWishList = ({ open, setOpen, t }) => {
  const history = useHistory();
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        maskClosable={false}
        footer={[
          <div key={1} className="wrap-btn-modal">
            <button
              type="submit"
              className="btn-modal"
              onClick={() => {
                handleOk();
                history.push("/wishlist");
              }}
            >
              {t("WishListView")}
            </button>
          </div>,
        ]}
      >
        <p className="added-wishList">
          <i className="fa fa-check-circle" /> {t("AddToWishList")}
        </p>
      </Modal>
    </>
  );
};
export default ModalWishList;

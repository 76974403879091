import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useUser } from "../context/user";
import { authenticate } from "../services/api/auth";
import useNotification from "../utils/notifications";

const useAuth = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { setUser } = useUser()
  const history = useHistory();

  const auth = async (values) => {
    const response = await authenticate(values);
    if (response.success) {
      showNotification("success", t("LoginSuccess"));
      localStorage.setItem("userInfo", JSON.stringify(response.data.user));
      setUser(response.data.user);
      form.resetFields();
      history.push("/");
    } else {
      showNotification("error", response.message);
      form.resetFields();
    }
  };

  const { mutate: user, isLoading } = useMutation({
    mutationFn: auth,
    onSuccess: (data) => {
      queryClient.setQueryData(["user"], () => {
        return data;
      });
    },
    onError: (data) => {
      showNotification("error", data?.response?.data?.message);
    }
  });

  return {
    form,
    user,
    isLoading,
  };
};

export default useAuth;

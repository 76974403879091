import { useState } from "react";
import { useTranslation } from "react-i18next";
import { create } from "../services/api/subscribers";
import useNotification from "../utils/notifications";
import { validEmail } from "../utils/regex";

const useSubscribers = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [email, setEmail] = useState({ email: "" });

  const toSubscribe = async () => {
    if (validEmail.test(email.email)) {
      const response = await create(email);
      if (response.success)
        showNotification("success", t("MessageSuccessSend"));
      else showNotification("error", t("MessageErrorSend"));
    } else {
      showNotification("warning", t("MessageWarningSend"));
    }
    setEmail({ email: "" });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setEmail({
      [name]: value,
    });
  };

  return {
    toSubscribe,
    onChange,
    email,
  };
};

export default useSubscribers;

import React, { useEffect } from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import MyAccount from "./shop-components/my-account";
// import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/user";

const MyaccountV1 = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { t } = useTranslation();
  const { user, logOut } = useUser();
  return (
    <div>
      <Navbar user={user} logOut={logOut} />
      <PageHeader headertitle={t("MyAccount")} t={t} />
      <MyAccount t={t} user={user} logOut={logOut} />
      <Footer t={t} />
    </div>
  );
};

export default MyaccountV1;

/*eslint-disable*/
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import get, { getRelated } from "../services/api/properties";
import { useEffect } from "react";

const useShopProperties = () => {
	const queryClient = useQueryClient();
	const { state } = useLocation();

	const { data: properties, isLoading } = useQuery("properties", {
		queryFn: async () => {
			const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
			const response =
				(await agencyInfo) !== null
					? getRelated({
							userId: agencyInfo?.user?.id,
							limit: 10,
							offset: 0,
					  })
					: get({ limit: 10, offset: 0 });
			return response;
		},
		enabled: state === undefined,
	});

	useEffect(() => {
		const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));

		if (agencyInfo !== null) {
			getByFormBannerAgency({
				...state,
				userId: agencyInfo?.user?.id,
				limit: 10,
				offset: 0,
			});
		} else {
			getByFormBanner({ ...state, limit: 10, offset: 0 });
		}
	}, [state]);

	const { mutate: getByFormBanner } = useMutation({
		mutationFn: get,
		onSuccess: (data) => {
			queryClient.setQueryData(["properties"], () => {
				return data;
			});
		},
	});

	const { mutate: getByFormBannerAgency } = useMutation({
		mutationFn: getRelated,
		onSuccess: (data) => {
			queryClient.setQueryData(["properties"], () => {
				return data;
			});
		},
	});

	return {
		properties,
		isLoading,
	};
};

export default useShopProperties;

import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const Features = ({ features, t }) => {
  return (
    <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{t("Features")}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
          {features.slice(0, 12).map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={item.id}>
              <div className="ltn__category-item ltn__category-item-4 text-center">
                <span
                  className="category-icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Icon
                    icon={item.icon}
                    style={{ fontSize: "60px", color: "black" }}
                  />
                </span>
                <span
                  className="category-title"
                  style={{
                    display: "block",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {item.name}
                </span>
              </div>
            </div>
          ))}
          <Link
            to="/shop"
            className="ltn__category-item ltn__category-item-3 category-btn btn btn-theme"
            style={{ width: "60%" }}
          >
            {t("InvestmentFooter")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Features;

import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import useWishList from "../../hooks/wishlist/useWishList";
import ModalWishList from "../shop-components/modal-wishList";
import { useConfiguration } from "../../context/configurations";

export const FixedButtonFavorite = ({ id }) => {
  const { open, setOpen } = useConfiguration();
  const { t } = useTranslation();
  const { addWishList, isLoading } = useWishList();

  return (
    <div
      style={{
        position: "fixed",
        bottom: "220px",
        right: "35px",
        zIndex: "2100",
        display: "block !important",
      }}
    >
      {open && <ModalWishList setOpen={setOpen} open={open} t={t} />}
      <Tooltip placement="left" title={t("Agregar")}>
        <Button
          onClick={() => addWishList(id)}
          type="primary"
          shape="circle"
          disabled={isLoading}
          style={{
            width: "60px",
            height: "60px",
            background: "#FFC400",
            borderColor: "#FFC400",
          }}
          icon={<i className="flaticon-heart-1" style={{ fontSize: "25px" }} />}
        ></Button>
      </Tooltip>
    </div>
  );
};

import { useMutation, useQueryClient } from "react-query";
import { remove } from "../../services/api/wishList";

const useWishListRemoveItem = () => {
  const queryClient = useQueryClient();

  const { mutate: removeWishListItem, isLoading } = useMutation({
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries(["wishList"]);
    },
  });

  const removeItem = async (values) => {
    const payload = {
      id: values.propertyId,
    };

    removeWishListItem(payload);
  };

  return {
    removeItem,
    isLoading,
  };
};

export default useWishListRemoveItem;

import React, { createContext, useState, useContext } from "react";

export const AgencyContext = createContext();

export const AgencyProvider = ({ children }) => {
  const useLocal = localStorage.getItem("agencyInfo") && localStorage.getItem("agencyInfo") !== undefined && localStorage.getItem("agencyInfo") !== 'undefined'
    ? JSON.parse(localStorage.getItem("agencyInfo") || '')
    : undefined;

  const [agency, setAgency] = useState(useLocal);

  const logOut = () => {
    localStorage.removeItem("agencyInfo");
    setAgency(undefined);
    window.location.replace('/');
  };

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
        logOut,
      }}
    >
      {children}
    </AgencyContext.Provider>
  );
};

export const useAgency = () => {
  return useContext(AgencyContext);
};

import React from "react";
import { Link } from "react-router-dom";

const Page_header = ({ customclass, headertitle,  t }) => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let CustomClass = customclass ? customclass : "";

    return (
      <div
        className={
          "ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " +
          CustomClass
        }
        data-bs-bg={publicUrl + "assets/img/bg/14.jpg"}
      >
        <div  className="container">
          <div  className="row">
            <div  className="col-lg-12">
              <div  className="ltn__breadcrumb-inner">
                <h1  className="page-title">{headertitle}</h1>
                <div  className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <Link to="/">
                        <span  className="ltn__secondary-color">
                          <i  className="fas fa-home" />
                        </span>{" "}
                        {t("Home")}
                      </Link>
                    </li>
                    {/* <li>{headertitle ? headertitle : t("ProductDetail")}</li> */}
                    <li>{headertitle}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default Page_header;

import HYDRA_API from "./config/hydraAPI";

export const create = async (payload) => {
	const url = `${process.env.REACT_APP_API_URL}addressUser/create`;

	const result = await HYDRA_API().post(url, payload);
	return result.data;
};

export const get = async (data) => {
	const url = `${process.env.REACT_APP_API_URL}addressUser/get`;

	const result = await HYDRA_API().post(url, data);
	return result.data.data;
};

export const update = async (data) => {
	const url = `${process.env.REACT_APP_API_URL}addressUser/update`;

	const result = await HYDRA_API().put(url, data);
	return result.data;
};


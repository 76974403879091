import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const Team_Details = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Agent Details" t={t}/>
        <TeamDetails />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default Team_Details


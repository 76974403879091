/*eslint-disable*/
import React, { createContext, useState, useContext, useEffect } from "react"
import { getToken } from "../services/api/token"

export const TokenContext = createContext()

export const TokenProvider = ({ children }) => {
  const tokenVTLocal = localStorage.getItem("tokenVT")
    ? localStorage.getItem("tokenVT")
    : undefined

  const [tokenVT, setTokenVT] = useState(tokenVTLocal)

  useEffect(() => {
    async function getData() {
      const token = await getToken()
      if (token.success) {
        setTokenVT(token.data.token)
        localStorage.setItem("tokenVT", token.data.token)
      }
    }
    getData()
  }, [])

  return (
    <TokenContext.Provider
      value={{
        tokenVT,
        setTokenVT
      }}
    >
      {children}
    </TokenContext.Provider>
  )
}

export const useToken = () => {
  return useContext(TokenContext);
};

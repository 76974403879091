import { Form } from "antd";
import { useTranslation } from "react-i18next";
import useNotification from "../utils/notifications";
import { create, get, update } from "../services/api/address";
import { useUser } from "../context/user";
import { useState } from "react";
import { useQuery } from "react-query";

const useAddress = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  const { data: address } = useQuery("address", {
    queryFn: async () => {
      const response = await get({ userId: user.id });
      return response;
    },
    onSuccess: (res) => {
      if (res?.length > 0)
        form.setFieldsValue({
          id: res[0].id,
          userId: res[0].userId,
          addressLine1: res[0].addressLine1,
          addressLine2: res[0].addressLine2,
          postalCode: res[0].postalCode,
          cityId: res[0].city.id,
          stateId: res[0].city.state.id,
          countryId: res[0].city.state.country.id,
          indicative: res[0].indicative,
          phone: res[0].phone,
        });
    },
  });

  const sendInformation = async () => {
    setIsLoading(true);
    if (!address) {
      const response = await create({
        ...form.getFieldsValue(),
        userId: user.id,
        createdBy: user.email,
        isMain: true,
      });
      if (response.success) {
        showNotification("success", t("MessageSuccessSend"));
        window.location.reload();
      } else showNotification("error", t("MessageErrorSend"));
    } else {
      const response = await update({
        ...form.getFieldsValue(),
        id: address[0].id,
        userId: user.id,
        createdBy: user.email,
      });
      if (response.success)
        showNotification("success", t("MessageSuccessSend"));
      else showNotification("error", t("MessageErrorSend"));
    }
    setIsLoading(false);
  };

  return {
    form,
    sendInformation,
    isLoading,
    address,
  };
};

export default useAddress;

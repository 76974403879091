import React from "react";

const Testimonial = ({ t }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{t("Testimonials")}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__testimonial-slider-5-active slick-arrow-2">
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <i className="flaticon-left-quote-1" />
                <p>
                  La Plataforma Ubikme es muy fácil de usar y te permite de
                  manera rápida cargar tus inmuebles con toda la información
                  necesaria.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img
                      src={
                        "https://cdn1.visiontravel.net/ubikme/testimonial/hector-harris.jpeg"
                      }
                      alt="Hector Harris"
                    />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Hector Harris</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <i className="flaticon-left-quote-1" />
                <p>
                  Ubikme es una plataforma innovadora, que combina alta
                  tecnologia con criterios específicos aplicables a lo
                  relacionado con el corretaje de inmuebles. No solo podrás
                  publicar tus inmuebles sino que las búsquedas de los clientes
                  para invertir en inmuebles. Felicidades a REAL VISION by
                  Vision Biz por estar siempre a la vanguardia en tecnología
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img
                      src={
                        "https://cdn1.visiontravel.net/ubikme/testimonial/martha-noguera.jpeg"
                      }
                      alt="Martha Noguera"
                    />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Martha Noguera</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__testimonial-item ltn__testimonial-item-7">
              <div className="ltn__testimoni-info">
                <i className="flaticon-left-quote-1" />
                <p>
                  Con Ubikme guardar los documentos de tus propiedades es muy
                  sencillo. La plataforma es muy amigable y rápida y el ahorro
                  de tiempo es considerable.
                </p>
                <div className="ltn__testimoni-info-inner">
                  <div className="ltn__testimoni-img">
                    <img
                      src={
                        "https://cdn1.visiontravel.net/ubikme/testimonial/hector-harris.jpeg"
                      }
                      alt="Martha Noguera"
                    />
                  </div>
                  <div className="ltn__testimoni-name-designation">
                    <h5>Deyanira Breto</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

import { Col, Form, Input, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import useRegister from "../../hooks/useRegister";

const Register = ({ t }) => {
  const { registerCustomer, form, onChangeEmail, existUser, messageExistUser } =
    useRegister();
  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h1 className="section-title">{t("Register")}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="account-login-inner">
              <Form
                form={form}
                layout="vertical"
                onFinish={registerCustomer}
                className="ltn__form-box contact-form-box"
              >
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} xl={24}>
                    <Form.Item
                      name="email"
                      label={<span className="form-labels">{t("Email")}</span>}
                      rules={[
                        { required: true, message: t("RequiredField") },
                        { type: "email", message: t("NotValidEmail") },
                        { max: 50, message: t("MaxLength").replace("{N}", 50) },
                      ]}
                    >
                      <Input onChange={onChangeEmail} />
                    </Form.Item>
                    {existUser && (
                      <p className="p-user-exist">
                        {messageExistUser}:{" "}
                        <Link
                          to="/forgot-password"
                          style={{
                            color: "#2f2b3dad",
                            textDecoration: "underline",
                          }}
                        >
                          {t("ForgotPass")}
                        </Link>
                      </p>
                    )}
                  </Col>
                  <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item
                      name="name"
                      label={<span className="form-labels">{t("Name")}</span>}
                      rules={[
                        { required: true, message: t("RequiredField") },
                        { max: 20, message: t("MaxLength").replace("{N}", 20) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} xl={12}>
                    <Form.Item
                      name="lastName"
                      label={
                        <span className="form-labels">{t("Lastname")}</span>
                      }
                      rules={[
                        { required: true, message: t("RequiredField") },
                        { max: 20, message: t("MaxLength").replace("{N}", 20) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} xl={12} className="item-form">
                    <Form.Item
                      name="password"
                      label={
                        <span className="form-labels">{t("Password")}</span>
                      }
                      rules={[
                        { required: true, message: t("RequiredField") },
                        { min: 8, message: t("LengthPass") },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} xl={12} className="item-form">
                    <Form.Item
                      name="confirmPassword"
                      dependencies={["password"]}
                      label={
                        <span className="form-labels">
                          {t("ConfirmPassword")}
                        </span>
                      }
                      rules={[
                        { required: true, message: t("RequiredField") },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(t("PasswordsDontMatch"))
                            );
                          },
                        }),
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    disabled={existUser}
                  >
                    {t("Register")}
                  </button>
                </div>
              </Form>
              <div className="by-agree text-center">
                <div className="go-to-btn mt-50 go-top">
                  <Link to="/login">{t("AlreadyAccount")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

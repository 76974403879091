import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import About from './section-components/about-v4';
import Team from './section-components/team-v1';
// import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user';

const About_v1 = () => {
    const { t } = useTranslation();
    const { user, logOut} = useUser();
    return <div>
        <Navbar user={user} logOut={logOut}/>
        <PageHeader headertitle={t("About")} t={t} />
        <About t={t}/>
        <Team t={t}/>
        {/* <CallToActionV1 /> */}
        <Footer t={t} />
    </div>
}

export default About_v1


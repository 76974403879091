import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ShogGrid from "./shop-components/shop-grid-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";

const ShopGrid_V1 = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Shop Grid" t={t} />
      <ShogGrid />
      <CallToActionV1 />
      <Footer t={t} />
    </div>
  );
};

export default ShopGrid_V1;

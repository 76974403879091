import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import Location from './section-components/location';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const LocationPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Location" customclass="mb-0" t={t}/>
        <Location  />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default LocationPage


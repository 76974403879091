import React from "react";
const ConditionsTerms = () => {
	return (
		<div className="container-terms">
			<p>Estipulaciones del CONTRATO</p>
			<p>
				En el presente Contrato se establecen los términos y condiciones,
				responsabilidad para el uso de los contenidos y del servicio del
				sitio web llamado UBIKME.NET
			</p>
			<p>
				Al acceder, consultar o utilizar el Sitio Web, UBIKME los
				Usuarios/as dan su consentimiento en cumplir los términos y
				condiciones establecidos en este Contrato. En caso de que usted no
				acepte cumplir con los presentes términos y condiciones, puede
				desistir del sitio, pero no podrá acceder ni utilizar, el “Sitio
				Web”.
			</p>
			<p>
				Este Sitio Web UBIKME está dirigido a personas de cualquier región
				que requieran usar los servicios, respetando lo establecido por
				regla general en este contrato y el acceder y utilizar el Sitio Web,
				lo harán bajo su propio riesgo y responsabilidad.
			</p>
			<p>CONDICIONES PARA EL USUARIO</p>
			<p>
				El Sitio Web UBIKME.NET y los servicios relacionados con el mismo se
				ofrecen a los Usuarios que tengan capacidad legal para otorgar
				contratos legalmente vinculantes según la legislación aplicable.
			</p>
			<p>
				Los USUARIOS deben ser mayor de edad para utilizar la Web UBIKME.NET
			</p>
			<p>El USUARIO brindará la siguiente información.</p>
			<p>
				El Sitio Web UBIKME ofrece al Usuario la publicación de Inmuebles
				para negociar, entre otros, comprar, vender, alquilar o rentar en
				las diferentes formas. Al facilitar o introducir la información en
				el Sitio Web (“Información del Usuario”), el Usuario otorga a UBIKME
				un derecho a publicar y utilizar el contenido de forma permanente,
				no exclusivo, irrevocable, libre de royalties, durante el tiempo
				máximo permitido por la legislación aplicable. El Usuario reconoce y
				acepta que UBIKME es un portal de publicación pasiva a través del
				cual los usuarios pueden conseguir cargar información de inmuebles
				para negocio, en el entendido que UBIKME no comprueba ni controla la
				Información del Usuario. Por tal motivo, UBIKME no asume garantía
				alguna en cuanto a la fiabilidad, precisión, integridad, validez o
				veracidad de la Información remitida por los usuarios, por lo que el
				usuario es el único responsable del manejo y uso de la información.
			</p>
			<p>
				UBIKME se reserva el derecho, de eliminar, retirar, negarse a
				reflejar o bloquear toda Información del Usuario que UBIKME
				considere como inaceptable, no apropiada, por cualquier motivo que
				UBIKME considere, inclusive para planes gratuitos o pagos sin
				derecho a indemnización y así lo acepta el usuario, agente, empresa
				inmobiliaria o publicador. En caso de que UBIKME reciba alguna
				notificación o información sobre la inaceptabilidad de determinada
				información facilitada por los usuarios, UBIKME podrá, con total
				discrecionalidad, investigar dicha información.
			</p>
			<p>
				AUTORIZACIÓN. El USUARIO manifiesta expresamente a través de su
				aceptación a estos términos, que es titular de derechos de autor
				sobre los contenidos o información que suministre o entregue al
				sitio web UBIKME para su procesamiento o publicación. El usuario
				concede a UBIKME en forma permanente, una autorización de uso
				gratuita, no exclusiva, para la reproducción, adaptación,
				compilación, almacenamiento y distribución de los contenidos por él
				suministrados a través de esta página de Internet. UBIKME reconocerá
				al Usuario su autoría sobre los contenidos suministrados. Y el
				USUARIO autoriza y acepta a UBIKME sublicenciar a sus filiales y
				subsidiarias los mismos contenidos.
			</p>
			<p>El USUARIO confirma que:</p>
			<p>Es mayor de edad.</p>
			<p>
				Es titular de los derechos que representa, suministra, entrega o
				transmite a UBIKME o sus empresas vinculadas.
			</p>
			<p>
				No está violando derechos de autor de terceras personas y excluirá a
				UBIKME y a sus sublicenciatarios frente a cualquier reclamación que
				se presente con ocasión de su uso.
			</p>
			<p>
				EL Usuario asume bajo su propia responsabilidad, el uso que de o las
				decisiones que tome con base en la información que recibe de
				nuestros sitios y es el único responsable.
			</p>
			<p>Exclusión de Cobertura y Responsabilidad.</p>
			<p>
				El Sitio Web UBIKME.NET no puede confirmar que cada Usuario es quien
				dice ser.
			</p>
			<p>
				UBIKME no garantiza la disponibilidad y continuidad del
				funcionamiento del Sitio Web y de los Servicios ofrecidos. No todos
				los Servicios y contenidos en general se encuentran disponibles para
				todas las áreas geográficas. Asimismo, UBIKME no garantiza la
				utilidad del Sitio Web y de los Servicios para la realización de
				ninguna actividad en particular, ni su infalibilidad y, en
				particular, aunque no de modo exclusivo, que los Usuarios puedan
				efectivamente utilizar el Sitio Web, acceder a las distintas páginas
				web que forman el Sitio Web o a aquéllas desde las que se prestan
				los Servicios.
			</p>
			<p>
				UBIKME no garantiza que el Sitio Web funcione libre de errores o que
				el Sitio Web y su servidor estén libres de virus informáticos u
				otros mecanismos lesivos.
			</p>
			<p>
				El Sitio Web y los Servicios se suministran tal como están, sin
				garantías de ninguna clase.
			</p>
			<p>
				UBIKME no garantiza la exactitud, la veracidad, la exhaustividad o
				la actualización de los contenidos, los Servicios, el software, los
				textos, los gráficos y los vínculos.
			</p>
			<p>
				En ningún caso UBIKME será responsable de cualquier daño o
				perjuicio, daños directos y/o indirectos, lucro cesante o pérdida de
				oportunidades, negocios o dinero que resulten del uso o de la
				imposibilidad de uso del Sitio Web.
			</p>
			<p>
				UBIKME excluye toda responsabilidad por los daños y perjuicios de
				toda naturaleza que pudieran deberse al accionar de terceros no
				autorizados respecto de los Datos Personales de los Usuarios, así
				como de los Servicios ofrecidos en el Sitio Web.
			</p>
			<p>Aceptación del USUARIO:</p>
			<p>
				El USUARIO asume todos los riesgos relativos a, o derivados de, el
				uso, consulta o acceso por su parte al sitio web. el sitio web se
				facilita “como está” y “según disponibilidad”, sin garantía alguna;
			</p>
			<p>
				Dentro de los límites legales, UBIKME declina expresamente y en este
				acto, toda garantía o responsabilidad, expresa o implícita, legal o
				de otro tipo, incluidas, entre otras: las garantías implícitas de
				calidad satisfactoria, idoneidad para el uso concreto, prohibición
				de uso indebido y las habituales en la práctica comercial o en las
				negociaciones.
			</p>
			<p>
				Aunque UBIKME adopta medidas de seguridad para proteger el sitio web
				y su contenido de todo tipo de ataques informáticos con el fin de
				reducir el nivel de riesgo, en este acto UBIKME no garantiza al
				usuario que : 1 el sitio web y su contenido estará siempre libre de
				errores o virus o no sufrirá nunca ataques de terceros; 2 el
				funcionamiento ininterrumpido y siempre seguro del sitio web; 3 la
				permanente disponibilidad del sitio; 4 que el sitio web cubra o no
				las necesidades del usuario; y 5 la fiabilidad, exactitud,
				integridad, validez o veracidad de la información facilitada por el
				usuario.
			</p>
			<p>RESPONSABILIDAD Y LIMITACIÓN DE RESPONSABILIDAD</p>
			<p>
				El usuario acepta asumir todos los riesgos asociados con, o
				derivados de, el uso del sitio web o la información facilitada por
				el usuario, incluyendo, entre otros, los riesgos de pérdidas
				económicas, los daños a la propiedad y los gastos de negociación con
				otros usuarios del sitio web (ya sean desconocidos, menores,
				extranjeros o personas que actúan bajo falsa identidad). el usuario
				se compromete también a exonerar de toda responsabilidad a UBIKME a
				la empresa matriz y a las empresas subsidiarias y afiliadas de ésta,
				así como a los directivos, encargados, comerciales y empleados de
				todas ellas, frente a las reclamaciones, demandas o reclamaciones de
				indemnización por daños y perjuicios (directos, indirectos y
				sobrevenidos) de cualquier tipo, conocidas o no, que puedan
				interponerse con base en, o que se deriven del uso del sitio web, la
				información facilitada por el usuario o las transacciones que se
				produzcan como resultado del uso del sitio web por parte del
				usuario. Siempre dentro de los límites legales, el usuario asume y
				acepta que en ningún caso, ni UBIKME ni su empresa matriz, las
				empresas subsidiarias y afiliadas de ésta, ni tampoco sus
				directivos, los comerciales, los empleados o los proveedores de
				todas ellas, asumirán responsabilidad alguna por las pérdidas o los
				daños directos, indirectos o sobrevenidos (incluidos, entre otros,
				los daños a la propiedad y los daños puramente económicos), ni por
				lucro cesante, pérdida de cartera de clientes, pérdida de ingresos o
				de datos o por interrupción de uso, sea cual sea la causa que los
				haya provocado – incumplimiento contractual, responsabilidad
				extracontractual (incluida la negligencia) o incumplimiento de
				garantía-, incluso aunque UBIKME hubiera sido advertida de la
				posibilidad de ocurrencia de dichas pérdidas o daños. en caso de que
				en alguna jurisdicción se prohíba la exclusión de ciertas garantías,
				la limitación de responsabilidad o el descargo de responsabilidad
				respecto a ciertos daños, la responsabilidad acumulada de UBIKME <strong>por
				daños no excederá de USD $10.00 (diez dólares de los estados unidos
				de américa).</strong>
			</p>
			<p>VÍNCULOS A OTROS SITIOS.</p>
			<p>
				El Sitio Web contiene vínculos a otros sitios de Internet. UBIKME no
				respalda los contenidos de estos sitios web. UBIKME no es
				responsable del contenido de los sitios web de terceros y no hace
				ninguna afirmación relativa al contenido o su exactitud en estos
				sitios web de terceros. El acceder a sitios web de terceras partes
				vinculados, lo hace bajo propio riesgo.
			</p>
			<p>CESIÓN O USO COMERCIAL NO AUTORIZADO.</p>
			<p>
				Usted acepta no realizar ningún uso comercial no autorizado del
				Sitio Web UBIKME
			</p>
			<p>
				Asimismo, el Usuario se compromete a utilizar el Sitio Web y los
				Servicios diligentemente y de conformidad con la ley aplicable y
				vigente y con estas Condiciones Generales. UBIKME.NET
			</p>
			<p>CANCELACIÓNES Y SUSPENSIONES</p>
			<p>
				UBIKME tiene la facultad, y no la obligación, de emitir
				advertencias, suspensiones temporales y cancelaciones permanentes
				(baja) de los Usuarios registrados por infracciones a las presentes
				Condiciones Generales, y/o cualquier aviso, reglamento de uso e
				instrucción puestos en conocimiento del Usuario por UBIKME.
			</p>
			<p>
				Sin perjuicio de lo indicado en el punto anterior, UBIKME se reserva
				el derecho, a su exclusivo criterio, de emplear todos los medios
				legales a su alcance en caso que Usted infrinja cualquiera de estas
				Condiciones Generales.
			</p>
			<p>INDEMNIZACIÓN.</p>
			<p>
				Usted acepta mantener indemne y exonerar a UBIKME de y contra
				cualquier cargo, acción o demanda, Usted acepta y reconoce que
				UBIKME es un mero intermediario o publicador pasivo de inmuebles,
				UBIKME es ajeno a cualquier relación que pudiera entablarse entre
				Usted y quienes publiquen avisos, anuncios y/o propuestas a través
				del Sitio Web. En tal sentido, Usted acepta no reclamar a UBIKME por
				ningún tipo de consecuencia derivada de tales relaciones.
			</p>
			<p>
				UBIKME puede crear y ofertar publicaciones por planes gratuitos o
				pagos, estas publicaciones tendrán fecha de inicio y fecha de
				terminación de la cual expira la publicación para el usuario en la
				web, El usuario debe conocer el tipo de plan, temporalidad y
				condiciones antes de contratar o publicar en UBIKME.
			</p>
			<p>
				UBIKME puede crear planes o publicaciones gratuitas, por periodos o
				temporadas determinadas o indeterminadas, de igual forma puede
				suspender o cancelar estos planes gratuitos en cualquier momento y
				el usuario acepta publicar gratis bajo esta condición y exonera a
				UBIKME de cualquier responsabilidad por la suspensión o cancelación
				de estos servicios.
			</p>
			<p>UTILIZACIÓN DEL SITIO Y OBLIGACIONES CONCRETAS</p>
			<p>
				Además de las obligaciones generales, el Usuario deberá: Utilizar el
				Sitio Web únicamente para los fines legalmente permitidos de buscar
				inmuebles o publicar inmuebles para negocio. Facilitar y mantener
				los datos personales que conforman la Información del Usuario
				Agentes Publicadores o Agencias de forma completa, correcta,
				actualizada y veraz; Enviar solamente material sobre el que el
				Usuario tenga los correspondientes derechos o licencia, poder o
				autorización para hacerlo; Utilizar su propio criterio, precaución y
				sentido común al gestionar las publicaciones y la información
				conseguida a través del Sitio Web; Asumir el riesgo de confiar en el
				contenido, o utilizar la información, facilitados por terceros.
				Publicar inmuebles o negocios donde se tenga la debida autorización
				del propietario o los propietarios legales del inmueble o negocio;
				en caso de que Ud., el Usuario, el agente, la agencia o el
				publicador no tenga la debida autorización para abstenerse de usar
				los servicios de UBIKME para publicaciones.
			</p>
			<p>CUENTA Y CONTRASEÑA</p>
			<p>
				Cuando el Usuario utiliza el Sitio Web, puede optar por abrir una
				cuenta en el Sitio. En ese caso, el Usuario recibirá, creará el
				número de cuenta virtual y la contraseña inicial. Es responsabilidad
				exclusiva del Usuario:
			</p>
			<p>
				Mantener la confidencialidad de dichos números de cuenta virtual y
				contraseña;
			</p>
			<p>Actualizar y comprobar frecuentemente su contraseña; y</p>
			<p>
				Notificar inmediatamente a UBIKME si tiene conocimiento del uso no
				autorizado de su cuenta o de cualquier vulneración de las medidas de
				seguridad al correo info@ubikme.net
			</p>
			<p>DURACIÓN Y TERMINACIÓN.</p>
			<p>
				La prestación del Servicio del Sitio Web tiene una duración
				indeterminada. Sin embargo, UBIKME está autorizada para dar por
				terminada o suspender la prestación del Servicio del Sitio Web y/o
				de cualquiera de los Contenidos en cualquier momento.
			</p>
			<p>CONSIDERACIONES GENERALES</p>
			<p>El Usuario asume y acepta que UBIKME</p>
			<p>
				No garantiza que el Usuario reciba efectivamente la información que
				requiere para la negociación inmobiliaria, a través del Sitio Web;
			</p>
			<p>
				UBIKME no se responsabiliza de las ofertas, publicaciones
				inmobiliarias y mucho menos la veracidad o comprobación de dichas
				ofertas ya que las publicaciones son efectuadas por los usuarios y
				no por UBIKME
			</p>
			<p>
				No garantiza la exactitud, integridad, validez o vigencia de la
				información incluida en la Web por terceras personas, agentes o
				empresas;
			</p>
			<p>
				UBIKME no revisa el material de terceros. Agentes o Empresas, y
				solamente permite la inclusión del material en el sitio web a fin de
				su publicidad.
			</p>
			<p>
				UBIKME es una empresa que participa en negocios inmobiliarios y es
				un medio de publicidad, sin embargo, El Usuario deberá usar su buen
				juicio, precaución y sentido común a la hora de evaluar las
				publicaciones que puedan interesarle tomando toda la debida
				precaución para identificar su origen y confiabilidad.
			</p>
			<p>
				El Usuario declara que entre él y UBIKME no existe relación alguna
				de contratación laboral, asociación, agencia o empresa conjunta,
				derivadas del acceso y uso del Sitio Web.
			</p>
			<p>
				El presente Contrato integra todos los acuerdos a que el Usuario y
				UBIKME han llegado en relación al acceso y uso del Sitio Web, y se
				suscribe de forma adicional a cualquier acuerdo vinculante que pueda
				existir entre el Usuario y UBIKME.
			</p>
			<p>
				La cantidad de Publicaciones gratuitas o pagas que UBIKME mantenga
				en sus planes u ofertas pueden ser suspendidas, eliminadas,
				modificadas o extendidas a criterio de UBIKME en cualquier momento;
				además, UBIKME puede determinar la temporalidad de la publicación
				gratuita o pagas aumentando o reduciendo el tiempo de publicación en
				la web.
			</p>
			<p>
				UBIKME puede en cualquier momento: cambiar, eliminar o mejorar
				servicios ofrecidos para la publicación, registro, afiliación, o
				utilización del sitio web, estas acciones son aceptadas y asumidas
				por el usuario, agente o Empresa Inmobiliaria, aún y cuando estas
				acciones pudieran afectar alguna de las partes.
			</p>
			<p>
				UBIKME se reserva el derecho a modificar total o parcialmente estas
				Condiciones Generales en cualquier momento. En caso de llevar a cabo
				alguna modificación, UBIKME indicará en el portal al Usuario que ha
				sido modificado indicando la fecha de modificación, por lo que el
				usuario verificará en el presente contrato la fecha de modificación,
				por lo que recomendamos al usuario revisar periódicamente las
				modificaciones que pudiera tener el contrato, además podrá UBIKME
				enviar aviso a la dirección de correo electrónico registrada para
				utilizar el Sitio Web y los Servicios. El Usuario acepta por parte
				de UBIKME que la comunicación a dicha dirección de correo
				electrónico tendrá plena validez como notificación suficiente.
				Asimismo, si el Usuario persiste en la utilización de los Servicios
				y/o el Sitio Web, se considerará que ha aceptado implícitamente las
				nuevas Condiciones Generales.
			</p>
			<p>
				En caso de declararse la nulidad de alguna de las cláusulas de estas
				Condiciones Generales, tal nulidad no afectará a la validez de las
				restantes, las cuales mantendrán su plena vigencia y efecto.
			</p>
			<p>
				Estas Condiciones Generales, constituyen la totalidad del acuerdo
				entre Usted y UBIKME respecto del uso del Sitio Web
			</p>
		</div>
	);
};

export default ConditionsTerms;

/*eslint-disable*/
import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import MainFocus from "./section-components/OurMainFocus";
import ProSlider from "./section-components/product-slider-v1";
import ApartmentPlans from "./section-components/apartment-v1";
import VideoV1 from "./section-components/video-v1";
import Features from "./section-components/features";
import Testimonial from "./section-components/testimonial-v1";
import Footer from "./global-components/footer";
import usePropertyType from "../hooks/usePropertyType";
import useStatusType from "../hooks/useStatusType";
import useBusinessType from "../hooks/useBussinesType";
import useFormBanner from "../hooks/useFormBanner";
import { useTranslation } from "react-i18next";
import useFeatures from "../hooks/useFeature";
import usePlans from "../hooks/usePlans";
import { useUser } from "../context/user";
import { useParams } from "react-router-dom";
import { get as getUserConfiguration } from "../services/api/userConfiguration";
import useNotification from "../utils/notifications";
import { useAgency } from "../context/agency";
import useOutstanding from "../hooks/useOutstanding";

const Home = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const { showNotification } = useNotification();
  const { setAgency } = useAgency();

  useEffect(() => {
    async function get() {
      if (code) {
        const response = await getUserConfiguration({ code });
        if (response?.data) {
          localStorage.setItem("agencyInfo", JSON.stringify(response.data[0]));
          setAgency(response.data[0]);
          return;
        } else {
          localStorage.removeItem("agencyInfo");
          setAgency(undefined);
          showNotification(
            "error",
            `${t("NotAgencyCode")} ${code}`,
            "topRight"
          );
        }
      }
    }
    get();
    return () => {
      localStorage.getItem("agencyInfo") !== undefined;
    };
  }, [code]);

  const { statusType } = useStatusType();
  const { propertyTypes } = usePropertyType();
  const { businessType } = useBusinessType();
  const { features } = useFeatures();
  const { homeFilterData, searchProperties, setPriceRange, priceRange } =
    useFormBanner();
  const { plans } = usePlans();
  const { outstanding } = useOutstanding();
  const { user, logOut } = useUser();

  return (
    <div>
      <Navbar
        CustomClass="ltn__header-transparent gradient-color-2"
        user={user}
        logOut={logOut}
      />
      <Banner
        propertyTypes={propertyTypes}
        statusType={statusType}
        businessType={businessType}
        homeFilterData={homeFilterData}
        searchProperties={searchProperties}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
        t={t}
      />

      <MainFocus
        customClassProp="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"
        businessType={businessType}
        t={t}
      />
      {outstanding !== undefined && (
        <ProSlider properties={outstanding} t={t} />
      )}
      {plans?.length > 0 && <ApartmentPlans plans={plans} t={t} />}
      <VideoV1 />
      <Features features={features} t={t} />
      <Testimonial t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Home;

import axios from "axios";
import HYDRA_API from "./config/hydraAPI";

export const getIp = async () => {
    const url = `https://api.ipify.org`;

    const result = await axios.get(url);
    return result.data;
};

export const postVisit = async (data) => {
    const url = `${process.env.REACT_APP_API_URL}property/visit`;

    const ipAddress = await getIp()

    const result = await HYDRA_API(ipAddress).post(url, data);
    return result.data.data;
};
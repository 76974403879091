import { Col, Row } from "antd";
import Places from "./selectPlaces";
import ListItem from "./listItem";
import usePropertyMap from "../../../../hooks/usePropertyMap";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useCommonContext } from "../../../../context/common";

const ListWithMap = ({
	setSelectedCity,
	selectedCity,
	component,
	businessType,
	propertyTypes,
	t,
}) => {
	const antIcon = (
		<LoadingOutlined style={{ fontSize: 42, color: "#ffc400" }} spin />
	);
	const {
		propertiesModified,
		setPropertiesModified,
		properties: propertiesforMap,
		filterPropertiesInBound,
		mapRef,
		isLoadingSearch,
		center,
		searchWithoutCity,
		totalProperties,
		nextOrPrev,
		handleChangeBusinessType,
		handleChangePropertyTypes,
	} = usePropertyMap();

	const { setShopTabs } = useCommonContext();
	return (
		<Row>
			<Col xs={24} sm={component === "home-banner" ? 24 : 12}>
				<Places
					propertiesforMap={propertiesforMap}
					propertiesModified={propertiesModified}
					setPropertiesModified={setPropertiesModified}
					selected={selectedCity}
					setSelected={setSelectedCity}
					filterPropertiesInBound={filterPropertiesInBound}
					mapRef={mapRef}
					isLoadingSearch={isLoadingSearch}
					center={center}
					searchWithoutCity={searchWithoutCity}
					component={component}
					businessType={businessType}
					propertyTypes={propertyTypes}
					handleChangeBusinessType={handleChangeBusinessType}
					handleChangePropertyTypes={handleChangePropertyTypes}
					t={t}
				/>
				{component === "home-banner" && (
					<div className="wrap-btn-map-home">
						<Link to="/shop">
							<button
								className="btn-filter"
								onClick={() => setShopTabs("2")}
							>
								{t("ViewProperties")}
							</button>
						</Link>
					</div>
				)}
			</Col>
			{component !== "home-banner" && (
				<Col xs={24} sm={12}>
					<div className="row-list-map">
						<Row>
							{propertiesModified?.length > 0 ? (
								propertiesModified?.map((item) => (
									<Col xs={24} sm={12} key={item.id}>
										<ListItem item={item} t={t} />
									</Col>
								))
							) : isLoadingSearch ? (
								<div className="spin-list-map-container">
									<div className="spin-list-map">
										<Spin indicator={antIcon} />
									</div>
									<p className="text-spin-list-map">
										{t("WaitProperties")}
									</p>
								</div>
							) : (
								<div className="spin-list-map-container">
									<p className="text-spin-list-map">
										{t("No se han encontrado resultados")}
									</p>
								</div>
							)}
						</Row>
					</div>
					<ReactPaginate
						activeClassName={"item-pagination active-pagination "}
						breakClassName={"item-pagination break-me "}
						breakLabel={"..."}
						containerClassName={"pagination"}
						disabledClassName={"disabled-page"}
						marginPagesDisplayed={2}
						nextClassName={"item-pagination next-pagination "}
						nextLabel=">"
						onPageChange={nextOrPrev}
						pageCount={totalProperties / 10}
						pageClassName={"item-pagination pagination-page "}
						pageRangeDisplayed={3}
						previousClassName={"item-pagination previous-pagination"}
						previousLabel="<"
					/>
				</Col>
			)}
		</Row>
	);
};

export default ListWithMap;

import HYDRA_API from "./config/hydraAPI";

export const forgotPassword = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}password/forgot`;

  return await HYDRA_API().post(url, payload);
};

export const verifyCode = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}password/verifyCode`;

  return await HYDRA_API().post(url, payload);
};

export const changePassword = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}password/restore`;

  return await HYDRA_API().post(url, payload);
};
